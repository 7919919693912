import { SET_PROFILE, UPDATE_PROFILE, CLEAR_PROFILE } from './actionTypes';

// Initial state for the user's profile
const initialState = {
    userType:null,
    creator: {
        id: '',
        location: '',
        bio: '',
        creator_code: '',
        deleted: '',
        created_at: '',
        updated_at: '',
        name: '',
        profile_pic_key: '',
        refferal: '',
        first_name: '',
        last_name: '',
        title: '',
        address: '',
        cognito_id: '',
        social_links: '',
        skills: '',
        alias: '',
        isNew: '',
        email: '',
    },

    school: {
        id: '',
        cognito_id: '',
        school_id: '',
        email: '',
        role: '',
        isOwner: '',
        profile_img: '',
        organization: '',
        reffer_by: '',
        signup_method: '',
        full_name: '',
        created_at: '',
        updated_at: '',
        deleted: '',
    },

    brand: {
        id: '',
        cognito_id: '',
        brand_id: '',
        email: '',
        role: '',
        isOwner: '',
        profile_img: '',
        organization: '',
        refer_by: '',
        describe_work: '',
        created_at: '',
        updated_at: '',
        deleted: '',
        signup_method: '',
        full_name: '',
    }
};

// The profile reducer function
const profileReducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_PROFILE:
            // Update specific profile (creator, school, or brand) based on the action payload
            if (action.payload.creator) {
                return {
                    ...state,
                    userType:'creator',
                    creator: { ...state.creator, ...action.payload.creator },
                };
            }
            if (action.payload.school) {
                return {
                    ...state,
                    userType:'school',
                    school: { ...state.school, ...action.payload.school },
                };
            }
            if (action.payload.brand) {
                return {
                    ...state,
                    userType:'brand',
                    brand: { ...state.brand, ...action.payload.brand },
                };
            }
            return state;

        case CLEAR_PROFILE:
            return {
                ...initialState, // Reset the profile to initial state
            };

        default:
            return state;
    }
};

export default profileReducer;
