import React from 'react'
import PrimaryButton from '../buttons/PrimaryButton'
import LoaderIcon from '../../icons/Loader'

const ChatInput = ({ chatprompt, setChatprompt, handleChatMessage, isLoading }) => {
    return (
        <div>
            <div className="border rounded-lg flex justify-between p-2 relative">
                <textarea
                    placeholder="Enter your prompt"
                    className="prompt-input attribute-values min-h-[90px] focus-visible:outline-none"
                    value={chatprompt}
                    onChange={(e) => setChatprompt(e.target.value)}
                />
                <PrimaryButton text="Send" disabled={(chatprompt && !isLoading) ? false : true} onClick={() => chatprompt && handleChatMessage()} className=" absolute right-6 bottom-3" />

            </div>
        </div>
    )
}

export default ChatInput




