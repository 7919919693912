import React, { useContext, useEffect, useRef, useState } from 'react'
import LoginLogo from '../LoginLogo'
import GoogleIcon from '../../icons/GoogleIcon'
import CommonHeading from './CommonHeading'
import InputField from '../form-elements/InputField'
import PrimaryFilledBtn from '../form-elements/PrimaryFilledBtn'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import OTPScreen from './OTPScreen'
import PrimaryButton from '../buttons/PrimaryButton'
import SecondaryButton from '../buttons/SecondaryButton'
import { authenticateUser, changePassword, checkUserEmail, signUpUser } from '../../services/aws/AwsService'
import useToast from '../../hooks/useToast'
import SelectDropDown from '../dropDown/SelectDropDown'
import MultiSelectDropDown from '../dropDown/MultiSelectDropdown'
import { CognitoUserAttribute } from 'amazon-cognito-identity-js'
import { editBrand, editBrandUser, getUploadUrl, sendInvitationMailToBrandUsers, uploadFileTos3 } from '../../services/brandServices'
import { ulid } from "ulid"
import { useDispatch, useSelector } from 'react-redux'
import { loginSuccess, profile } from '../../redux/actions'
import GoogleAuth from './GoogleAuth'
import { SidebarContext } from '../../App'
import InputText from '../FormComponets/InputText'
import AddRounded from '../../icons/AddRounded'
import DeleteIcon from '../../icons/Delete'
import { filterEmailsByDomain } from '../../utils/filterEmailByDomain'
import { hasExpired } from '../../utils/hasExpired'
import CameraPlus from '../../icons/CameraPlus'
import checkRestrictedEmailDomains from '../../utils/checkRestrictedEmailDomains'
import useQueryParam from '../../hooks/useQuery'

import { editUserProfile } from '../../services/creatorService'
import { editSchool, editSchoolUser, sendInvitationMailToSchoolUsers } from '../../services/schoolServices'
import { useAuthProvider } from '../../contexts/AuthContext'
import defPasswordGen from '../../utils/defPasswordGen'
import { validateEmail } from '../../utils/validateEmail'


// const defPasswordGen = (password) => {
//     const genPassword = password + "Swiirl-def-password-123"
//     return genPassword
// }

const INVITESTATE = {
    invitedByEmail: "",
    userEmail: "",
    expTime: "",
}


function SignupMain() {
    const { getQueryParam, setQueryParams } = useQueryParam();
    const { signupData, changeSignupData } = useAuthProvider()
    const { userType } = signupData

    const [invitationState, setInvitationState] = useState(INVITESTATE)
    const dataRef = useRef()
    const [email, setEmail] = useState("")
    const navigate = useNavigate()
    const location = useLocation()
    const [signupMode, setSignupMode] = useState("password")  //email or google
    const [error, setError] = useState('')
    const [loading, setLoading] = useState('')
    const showToast = useToast()
    const [cognitoId, setCognitoId] = useState('')
    const [isInvited, setIsInvited] = useState(false)

    const [screenNo, setScreenNo] = useState(1)

    const [emailExistNotVeryfied, setIsEmailExistNotVeryfied] = useState(false)

    const screen = getQueryParam('screen');


    // useEffect(() => {
    //     if (!screen) {
    //         navigate('/', { replace: true })
    //     }
    //     setScreenNo(screen)
    // }, [screen])

    const handleEmail = (email_) => {
        let newEmail = email_.trim()
        setEmail(newEmail)
    }

    useEffect(() => {
        //     const search = location.search;
        //     const email = new URLSearchParams(search).get('email');
        //     const invitedBy = new URLSearchParams(search).get('invitedBy');
        //     const expTime = new URLSearchParams(search).get('expTime');
        //     const id = new URLSearchParams(search).get('id');

        const { email, invitedBy, expTime, userType } = signupData

        // handle exp time
        if (hasExpired(expTime)) {
            alert('Your invitation has been expired')
            changeSignupData({ screenType: 'start' })
        }

        if (email && expTime && invitedBy) {
            setIsInvited(true)
            handleEmail(email);
            setInvitationState({
                expTime, invitedByEmail: invitedBy, userEmail: email, userType
            })
        }


    }, [])






    useEffect(() => {

        if (location?.state && !location.state?.referral_code) {
            const { email, cognitoId, screenNo, signupType } = location?.state
            handleEmail(email)
            setCognitoId(cognitoId)
            setScreenNo(screenNo)

            setSignupMode(signupType)
        }

    }, [location])





    const addUserInCognito = async () => {
        const attributeList = [
            new CognitoUserAttribute({
                Name: "email",
                Value: email,
            }),
            new CognitoUserAttribute({
                Name: "locale",
                Value: userType || "brand",
            }),
            new CognitoUserAttribute({
                Name: "name",
                Value: email?.split("@")[0] || "",
            }),
        ];
        const passwordDummy = await defPasswordGen(email)
        const resp = await signUpUser(email, passwordDummy, attributeList)
        setCognitoId(resp?.cognitoId || "")
    }

    // check is email existed in  congito  or not
    const checkUserExisted = async () => {
        setLoading(true)
        const res = await checkUserEmail(email)
        setCognitoId(res?.cognitoId || "")

        const { emailExists, emailVerified } = res
        if (emailExists && emailVerified) {
            showToast('Email is already registered')
        }
        else {
            if (emailExists) {
                setIsEmailExistNotVeryfied(true)
                changeScreen(2)
            }
            else {
                setIsEmailExistNotVeryfied(false)
                await addUserInCognito()
                changeScreen(2)
            }

        }
        setLoading(false)
    }

    const handleGetStarted = async () => {
        if (!email) return
        const emailCheck = userType !== 'creator' && await checkRestrictedEmailDomains(email);
        if (emailCheck) {
            showToast("Please provide your work email address.", 'error')
            return
        }
        checkUserExisted()
    }

    // --------------------------


    // ---user to chagen signup page screen------
    const changeScreen = (screen) => {
        if (screen > 5) {
            navigate("/", {
                replace: true
            })
        }
        changeSignupData({ screenNo: screen })
        setScreenNo(screen)
        setQueryParams({
            screen: screen
        })
    }









    return (
        <>
            {
                screenNo == 1 ?

                    <div className='flex flex-col h-full  items-center justify-center'>

                        <div className="w-full flex items-center flex-col">
                            <div className=" flex flex-col gap-4 w-[400px] items-center">
                                <CommonHeading backBtnClick={() => navigate(-1)} header={'Welcome to Swiirl'} subHeading={'To get started, please sign up'} />

                                <div className="flex flex-col gap-2 w-full mt-2">
                                    <InputField disabled={isInvited ? true : false} value={email} onChange={e => handleEmail(e.target.value)} name="email" label="Email" labelClass='mb-2 imp' placeholder='Enter your work email' type='email' />
                                </div>
                                {
                                    isInvited ?
                                        <PrimaryFilledBtn disable={loading} label={loading ? "Checking..." : 'Get Started'} className='mt-top' onClick={handleGetStarted} />
                                        :
                                        <>

                                            <PrimaryFilledBtn disable={loading} label={loading ? "Checking..." : 'Get Started'} className='mt-top' onClick={() => {
                                                setSignupMode('password')
                                                handleGetStarted();
                                            }} />
                                            <p className="font-semibold text-lg -mb-1">OR</p>

                                            <div className='w-full' onClick={() => setSignupMode('google')
                                            }>

                                                <GoogleAuth
                                                    buttonClass='flex flex-row justify-center items-center gap-2 w-full py-2 rounded-md border-1 border-gray-400 cursor-pointer font-bold'
                                                    text="Sign up with your Google work account"
                                                    icon={<GoogleIcon />}
                                                    type="signup"

                                                    changeScreen={changeScreen}
                                                />
                                            </div>

                                        </>


                                }
                                {/* signupMode === 'password' ? 
                                             :
                                            <>

                                                <GoogleAuth
                                                    buttonClass='flex flex-row justify-center items-center gap-2 w-full py-2 rounded-md border-1 border-gray-400 cursor-pointer font-bold'
                                                    text="Sign up with your Google work account"
                                                    icon={<GoogleIcon />}
                                                    type="signup"
                                                    changeScreen={changeScreen}

                                                />
                                                <p className="font-semibold text-lg -mb-1">OR</p>
                                                <PrimaryFilledBtn label='Sign up with email' className='mt-top mb-2' onClick={() => setSignupMode('password')} />
                                            </> */}
                                <p className="text-lg -mt-4">Already have an account? <Link to='/login'><span className="text-violet">Log in</span> </Link> </p>
                                <p className="-mt-4 text-center text-sm">
                                    By signing up, I agree to the Swiirl  <Link to={userType === 'creator' ? 'https://www.swiirl.io/terms-and-conditions-creator' : userType === 'school' ? 'https://www.swiirl.io/terms-and-conditions-school-org' : 'https://www.swiirl.io/terms-and-conditions-brand'} target='_blank' className="text-[#4285F4]"> Terms and Conditions</Link> and acknowledge that the <Link target='_blank' to='https://www.swiirl.io/privacy-policy' className="text-[#4285F4]"> Privacy Policy</Link> applies
                                </p>


                            </div>
                        </div>
                    </div>

                    :
                    <div className="w-full min-h-screen ">
                        <div className="flex flex-col sm:py-8 sm:px-12 w-full">
                            <LoginLogo />
                            <div className='flex flex-col  items-center justify-centers mt-28'>
                                {screenNo == 2 && signupMode === "password" &&
                                    <OTPScreen
                                        email={email}
                                        changeScreen={changeScreen}
                                        emailExistNotVeryfied={emailExistNotVeryfied}
                                        cognitoId={cognitoId}
                                        signupMode={signupMode}
                                        invitationState={invitationState}
                                    />
                                }
                                {screenNo == 3 && <Screen3
                                    changeScreen={changeScreen}
                                    cognitoId={cognitoId}
                                    email={email}
                                    dataRef={dataRef}
                                    signupMode={signupMode}
                                />}

                                {screenNo == 4 && <Screen4
                                    changeScreen={changeScreen}
                                    cognitoId={cognitoId}
                                    email={email}
                                    signupMode={signupMode}
                                />}
                                {screenNo == 5 && <Screen5
                                    changeScreen={changeScreen}
                                    dataRef={dataRef}
                                    signupMode={signupMode} />}

                            </div>

                        </div>
                    </div>
            }

        </>

    )
}

export default SignupMain






const ScreenLayer = ({ heading, subHeading, children }) => {
    return (
        <div className='w-full ps-[5vw]'>
            <h1>{heading}</h1>
            <p>{subHeading}</p>
            {children}
        </div>
    )
}



const rolesOptionSchool = [
    { value: "exec_director", label: "Exec Director" },
    { value: "superintendent_principal", label: "Superintendent Principal" },
    { value: "administrator", label: "Administrator" },
    { value: "teacher", label: "Teacher" },
    { value: "program_coordinator", label: "Program Coordinator" },
    { value: "PTSA", label: "PTSA" },

]

const Screen3 = ({ changeScreen, cognitoId, email, dataRef, signupMode }) => {
    const showToast = useToast()
    const { signupData, changeSignupData } = useAuthProvider()
    const navigate = useNavigate()
    const { userType } = signupData
    const { isOwner, username } = useSelector(state => state.user)

    // const userType = "school"

    const userState = useSelector(state => state.user)

    const { setGlobalLoader } = useContext(SidebarContext)
    const [loading, setLoading] = useState(false)
    const [fullName, setFullName] = useState('')
    const [firstName, setFirstName] = useState()
    const [lastName, setLastName] = useState()

    const [role, setRole] = useState()

    const [password, setPassword] = useState("")
    const [profileImg, setProfileImg] = useState("")
    const dispatch = useDispatch()
    const [file, setFile] = useState(null)
    const [profileImgS3Key, setProfileImgS3Key] = useState('')
    const [passwordErr, setPasswordErr] = useState('')




    const validatePassword = (password) => {
        const minLength = 8;
        const hasNumber = /\d/.test(password);
        const hasSpecialChar = /[!@#$%^&*(),.?":{ }|<>]/.test(password);
        const hasUppercase = /[A-Z]/.test(password);
        const hasLowercase = /[a-z]/.test(password);

        let errMsg = [];

        if (password.length < minLength) {
            errMsg.push(' Password must be at least 8 characters long.\n')

        }
        if (!hasNumber) {
            errMsg.push('Password must contain at least 1 number.\n')

        }
        if (!hasSpecialChar) {
            errMsg.push('Password must contain at least 1 special character.\n')

        } if (!hasUppercase) {
            errMsg.push('Password must contain at least 1 uppercase letter.\n')

        } if (!hasLowercase) {
            errMsg.push('Password must contain at least 1 lowercase letter.\n')

        }

        // errMsg = errMsg.join('')
        // const errors = {
        //     minLength: password.length >= minLength,
        //     hasNumber,
        //     hasSpecialChar,
        //     hasUppercase,
        //     hasLowercase,
        // };

        return errMsg;
    };



    // --------reset password for replace default password for cognito account----------------
    const passwordReset = async (password) => {
        const genPassword = defPasswordGen(username || email)

        const res = await changePassword(username || email, genPassword, password)
    }


    // Handle image selection
    const handleImageChange = async (e) => {
        const file = e.target.files[0];
        if(!file?.type?.includes('image')){
            showToast('Only jpg, jpeg, svg, png or gif are accepted',"error")
            return 
          }
        setFile(file)
        if (file) {
            setProfileImg(URL.createObjectURL(file)); // Create a URL for the selected image
            const key = `profile/${userType || 'NA'}/${cognitoId}/${ulid()}`;
            setProfileImgS3Key(key)
        }
    };

    const handleClick = async () => {

        setGlobalLoader(true)
        setLoading(true)
        const passwordError = await validatePassword(password)
        if (passwordError && passwordError.length > 0 && (signupMode !== "google")) {
            setPasswordErr(passwordError)
            setGlobalLoader(false)
            setLoading(false)
            return
        }

        if (userType === "creator" && !(firstName || lastName)) {
            showToast("First and last name is required.", 'error')
            setGlobalLoader(false)
            setLoading(false)
            return
        }


        try {
            let profImgUrl = ''
            // ---genrate upload url and upload profileimage-------
            if (profileImgS3Key) {
                const { urls, getUrls } = await getUploadUrl([profileImgS3Key], true);

                try {
                    await uploadFileTos3(urls[0], file)
                    profImgUrl = getUrls[0] || profileImg
                    setProfileImg(getUrls[0] || profileImg)
                } catch (error) {
                    console.log(error)
                }
            }
            // -----------edit brand user -----------------

            if (userType === "brand") {
                let payload = {
                    cognitoId,
                    fullName,
                    profileImg: profileImgS3Key || ""
                }
                const resp = await editBrandUser(payload)

            }
            else if (userType === "creator") {
                let payload = {
                    cognitoId,
                    creator_id: userState?.userId,
                    first_name: firstName,
                    last_name: lastName,
                    profile_pic_key: profileImgS3Key || ""
                }
                const resp = await editUserProfile(payload)

            }
            else if (userType === "school") {
                let payload = {
                    cognitoId,
                    fullName,
                    role,
                    profileImg: profileImgS3Key || ""
                }

                const resp = await editSchoolUser(payload)

            }




            // -----------set password for profile--------
            if (signupMode !== "google") {
                try {
                    const resp = await passwordReset(password)   // if signup type is google  then hide it
                } catch (error) {
                    console.log(error)
                }
            }

            dataRef.current = {
                email, password
            }
            setGlobalLoader(false)

            if (userType === "creator" || (userType === "school" && !isOwner)) {
                dispatch(loginSuccess({ ...userState, isLoggedIn: true, fullName }))
                navigate('/', {
                    replace: true
                })
                setGlobalLoader(false)
                setLoading(false)

                return
            }
            dispatch(profile(profImgUrl || profileImg))
            changeScreen(4)
            setGlobalLoader(false)
            setLoading(false)
        } catch (error) {
            console.log(error)
            setGlobalLoader(false)
            setLoading(false)
        }
    }


    return (
        <ScreenLayer heading='Welcome To Swiirl!' subHeading='Let’s set up your profile'>
            <div className='w-[500px]'>
                <div className='flex gap-3 mt-5 items-start '>
                    <div className='w-52 flex  items-center justify-start '>
                        {/* Display the selected image in a rounded shape */}
                        <label htmlFor="upload-input" className='w-48 h-48 border-1  rounded-full flex items-center justify-center cursor-pointer relative overflow-hidden'>
                            {profileImg ? (
                                <img
                                    src={profileImg}
                                    alt="Profile"
                                    className='w-full h-full rounded-full object-cover absolute'
                                />
                            ) : (
                                <CameraPlus />
                            )}
                            <input
                                type="file"
                                id='upload-input'
                                accept="image/*"
                                onChange={handleImageChange}
                                className='hidden'
                            />
                        </label>
                    </div>
                    <div className='w-3/5 flex flex-col gap-3'>
                        <div className='flex flex-col gap-3'>
                            {userType === "creator" && (
                                <div className='flex flex-col gap-3'>
                                    <InputField
                                        value={firstName}
                                        onChange={e => setFirstName(e.target.value)}
                                        labelClass="mb-2 text-black"
                                        name="firstName"
                                        label="What is your first name?"
                                        type="text"
                                        placeholder='Enter your first name'

                                    />
                                    <InputField
                                        value={lastName}
                                        onChange={e => setLastName(e.target.value)}
                                        labelClass="mb-2 text-black"
                                        name="lastName"
                                        label="What is your last name?"
                                        type="text"
                                        placeholder='Enter your last name'

                                    />
                                </div>
                            )}

                            {(userType === "brand" || userType == "school") && (
                                <InputField
                                    value={fullName}
                                    onChange={e => setFullName(e.target.value)}
                                    labelClass="mb-2 text-black"
                                    name="fullName"
                                    placeholder='Enter your full name'
                                    label="What is your full name?"
                                    type="text"
                                />
                            )}

                            {userType == "school" && (
                                <div className="flex flex-col w-full">
                                    <p className="mb-1 text-slate-700">What is your role?</p>
                                    <SelectDropDown
                                        selectType="single"
                                        className="w-full "
                                        options={[...rolesOptionSchool]}

                                        value={role}
                                        onChange={(e) => setRole(e.target.value)}
                                    />
                                </div>
                            )}
                        </div>

                        {
                            signupMode !== "google" &&
                            <div>
                                <InputField
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                    labelClass="mb-2 text-black"
                                    name="password"
                                    label="Password"
                                    type='password'
                                    placeholder='Create your password'

                                />
                                {passwordErr ? passwordErr.map(err => {
                                    return <div className='mt-1 text-sm text-red-600'>{err}</div>

                                }) :
                                    <p className='mt-1 text-sm text-gray-600'>{"Create a password with at least 8 characters"}</p>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>

            <div className='mt-4'>
                <PrimaryButton text="Continue" disabled={loading} className='w-[150px] font-[600] mt-3' onClick={handleClick} />
            </div>
        </ScreenLayer>
    );
};




const rolesOption = [
    { value: "executive", label: "Executive" },
    { value: "director", label: "Director" },
    { value: "manager", label: "Manager" },
    { value: "coordinator", label: "Coordinator" },
    { value: "specialist", label: "Specialist" },
    { value: "assistant", label: "Assistant" },
    { value: "consultant", label: "Consultant" },
    { value: "other", label: "Other" }
]
const describeWorkOption = [
    { value: "content_creation", label: "Content Creation" },
    { value: "brand_strategy", label: "Brand Strategy" },
    { value: "social_media_management", label: "Social Media Management" },
    { value: "advertising_media_buying", label: "Advertising and Media Buying" },
    { value: "public_relations", label: "Public Relations" },
    { value: "creative_direction", label: "Creative Direction" },
    { value: "community_engagement", label: "Community Engagement" },
    { value: "marketing_promotions", label: "Marketing and Promotions" },
    { value: "other", label: "Other" }
];

const institutionOptionsSchool = [
    { value: "middle_school", label: "Middle School" },
    { value: "high_school", label: "High School" },
    { value: "college", label: "College" },
    { value: "creative_arts", label: "Creative Arts" },
    { value: "non_profit", label: "Non Profit" },
    { value: "other", label: "Other" }
]
const Screen4 = ({ changeScreen, cognitoId }) => {
    const { setGlobalLoader } = useContext(SidebarContext)
    const { signupData, changeSignupData } = useAuthProvider()
    const navigate = useNavigate()
    const { userType } = signupData
    // const userType = "school"
    const { isOwner, schoolId, brandId } = useSelector(state => state.user)
    const [loading, setLoading] = useState(false);
    const { refresh, setRefresh } = useContext(SidebarContext)
    const [role, setRole] = useState();
    const dispatch = useDispatch()
    const [describeWork, setDescribeWork] = useState([]);
    const userState = useSelector(state => state.user)

    const [brandName, setBrandName] = useState('')


    const [schoolName, setSchoolName] = useState('')
    const [schoolType, setSchoolType] = useState('')
    const [location, setLocation] = useState('')

    const handleClick = async (isSkip = false) => {
        setLoading(true);
        setGlobalLoader(true)
        try {

            if (!isSkip) {
                if (userType === 'brand') {
                    const payload = {
                        cognitoId,
                        role,
                        describeWork: describeWork.length ? describeWork.join(', ') : ''
                    };

                    const resp = await editBrandUser(payload);
                    if (brandName) {
                        const payload = {
                            brandName,
                            brandId

                        }
                        const resp = await editBrand(payload);

                    }
                }
                else if (userType === 'school') {

                    let payload = {
                        cognitoId,
                        schoolId: schoolId || "",
                        schoolName,
                        schoolType,
                        location,
                    }

                    const resp = await editSchool(payload)

                    // dispatch(loginSuccess({ ...userState, isLoggedIn: true }))
                    // navigate('/', {
                    //     replace: true
                    // })
                }
            }
            setGlobalLoader(false)
            if (isOwner) {
                changeScreen(5);
            } else {
                dispatch(loginSuccess({ ...userState, isLoggedIn: true }))
                navigate('/', {
                    replace: true
                })

            }

            setLoading(false);
        } catch (error) {
            setGlobalLoader(false)
            console.log(error);
            setLoading(false);
        }
    };
    const handleDescribeWorkChange = (e) => {
        setDescribeWork(e.target.value);
    };



    return (
        <ScreenLayer className='' heading={userType === "school" ? "Tell us about School or Org" : 'Tell us about your work'} subHeading={'This will help tailor Swiirl for you.'}>
            <div className='col-3 my-12'>
                <div className='flex flex-col gap-6'>
                    {
                        userType === "school" ?
                            <>
                                <InputField
                                    value={schoolName}
                                    onChange={e => setSchoolName(e.target.value)}
                                    labelClass="mb-2 text-black"
                                    name="firstName"
                                    label="What is the name of your school or organization?"
                                    type="text"
                                    placeholder='Enter School / Org Name'

                                />
                                <div className="flex flex-col w-full">
                                    <p className="mb-1 text-slate-700">What type of institution are you?</p>
                                    <SelectDropDown
                                        selectType="single"
                                        className="w-full "
                                        options={[...institutionOptionsSchool]}

                                        value={schoolType}
                                        onChange={(e) => setSchoolType(e.target.value)}
                                    />
                                </div>
                                <InputField
                                    value={location}
                                    onChange={e => setLocation(e.target.value)}
                                    labelClass="mb-2 text-black"
                                    name="firstName"
                                    label="Where are you located"
                                    type="text"
                                    placeholder='Enter Address, City, State, Zip/Postal'

                                />
                            </>
                            :
                            <>
                                {isOwner ?
                                    <InputField
                                        value={brandName}
                                        onChange={e => setBrandName(e.target.value)}
                                        labelClass="mb-2 text-black"
                                        name="brandName"
                                        label="Your Company Name"
                                        type="text"
                                        placeholder='Enter your Company Name'

                                    />
                                    : ""
                                }
                                <div className="flex flex-col w-full">
                                    <p className="mb-1 text-slate-700">What is your role?</p>
                                    <SelectDropDown
                                        selectType="single"
                                        className="w-full"
                                        options={[...rolesOption]}
                                        value={role}
                                        onChange={(e) => setRole(e.target.value)}
                                    />
                                </div>
                                <div className="flex flex-col w-full">
                                    <p className="mb-1 text-slate-700">Which function best describes your work?</p>
                                    <MultiSelectDropDown
                                        options={describeWorkOption}
                                        value={describeWork} // Ensure this is an array of values
                                        onChange={handleDescribeWorkChange}
                                        name="describeWork"
                                        // dropDownLabel="Describe your work"
                                        className="w-full"
                                    />

                                </div>

                            </>
                    }
                </div>
            </div>

            <div className='flex items-center gap-5 mt-3'>
                <PrimaryButton text="continue" className='w-[150px] font-[600]' onClick={() => handleClick(false)} />
                <SecondaryButton text="skip" className='w-[150px] border border-gray-200 rounded-lg' onClick={() => handleClick(true)} />
            </div>
        </ScreenLayer>
    );
};



const Screen5 = ({ changeScreen, dataRef }) => {
    const { setGlobalLoader } = useContext(SidebarContext)
    const navigate = useNavigate()
    const [skipLoader, setSkipLoader] = useState(false)
    const [continueLoader, setContinueLoader] = useState(false)
    const userState = useSelector(state => state.user)
    const dispatch = useDispatch()
    const { refresh, setRefresh } = useContext(SidebarContext)
    const { signupData, changeSignupData } = useAuthProvider()

    const [invitationmails, setInvitationmails] = useState([""])
    const [mailDomain, setMailDomain] = useState(userState?.username?.split('@')[1])
    const [orgEmail, setOrgEmail] = useState(userState?.username)
    const showToast = useToast()
    async function handleLogin() {
        dispatch(loginSuccess({ ...userState, isLoggedIn: true }))
        setRefresh(!refresh)
        navigate("/", {
            replace: true
        });
    }


    async function handleSkip() {
        try {
            setSkipLoader(true)
            await handleLogin()
        } catch (e) {
            console.log(e)
        } finally {
            setSkipLoader(false)
        }
    }
    const handleSendInvitation = async () => {
        if (!orgEmail?.length || !invitationmails.length) {
            showToast("Organization email and invite mails are required")
            return
        }
        const emailValue = invitationmails[invitationmails.length - 1]
        if ((emailValue.split('@')).length !== 2 || !(emailValue.split('@'))?.[0]) {
            showToast("Enter Valid email.", 'error')
            return
        }

        try {
            setGlobalLoader(true)
            const payload = {
                "emails": invitationmails,
                "invitedBy": orgEmail
            }

            const invalidMails = filterEmailsByDomain(payload)
            const OrgName = orgEmail.split('@')[1].split('.')[0]

            if (invalidMails?.length) {
                showToast(`The following email address does not belong to the ${OrgName} organization:`, 'error')
                setGlobalLoader(false)

                return
            }
            let res
            if (signupData.userType === 'brand') {
                res = await sendInvitationMailToBrandUsers({ payload })
            }
            else if (signupData.userType === 'school') {
                res = await sendInvitationMailToSchoolUsers({ payload })
            }
            const invitedMails = res.map(item => item.label)
            const failedInviteMails = invitationmails.filter(item => !invitedMails.includes(item))
            setInvitationmails(prev => prev.filter(item => failedInviteMails.includes(item)))
            showToast("Invitation link sent", "success")
            if (!failedInviteMails.length) {
                setGlobalLoader(false)
                return true
            }
            showToast(`Invitation failed for: ${failedInviteMails.join(', ')}`, "error")
            setGlobalLoader(false)
            return false
        } catch (e) {
            console.log(e)
            showToast('Something went wrong', 'error')
            setGlobalLoader(false)

        }
    }

    async function handleContinue() {
        try {

            setContinueLoader(true)
            setGlobalLoader(true)
            const isSuccess = await handleSendInvitation()
            if (isSuccess) {
                await handleLogin()
            }
        } catch (e) {
            console.log(e)
        } finally {
            setContinueLoader(false)
            setGlobalLoader(false)

        }
    }
    return (
        <ScreenLayer className='' heading='Invite your teammates' subHeading={'Tagline needed here'}>
            <div className='my-12 flex flex-col gap-3'>
                <InputField
                    // value={orgEmail}
                    // onChange={e => setOrgEmail(e.target.value)}
                    value={'@' + mailDomain}
                    labelClass="mb-2 text-black"
                    name="email"
                    label="Email address"
                    placeholder=''
                    type='email'
                    className='col-3 font-bold cursor-not-allowed'
                    disabled={true}
                />
                {
                    invitationmails && invitationmails.map((item, index) => (
                        <div key={index} className='flex items-center gap-3'>
                            <div className='col-3'>
                                <InputText
                                    className='w-full p-2 '
                                    placeholder="Teammates's email"
                                    value={item}
                                    onChange={(e) => {
                                        const updatedLinks = [...invitationmails];
                                        updatedLinks[index] = e.target.value;
                                        setInvitationmails(updatedLinks);
                                    }}
                                />
                            </div>
                            <div
                                onClick={() => {
                                    if (invitationmails.length - 1 === index) {
                                        const emailValue = invitationmails[invitationmails.length - 1]?.trim()
                                        if (validateEmail(emailValue)) {
                                            const updateDmail = [...invitationmails];
                                            updateDmail[index] = emailValue;
                                            setInvitationmails(updateDmail);
                                            setInvitationmails([...updateDmail, ""]);
                                        }
                                        else {
                                            showToast("Enter Valid email.", 'error')
                                        }
                                    } else {
                                        setInvitationmails(invitationmails.filter((_, ind) => ind !== index));
                                    }
                                }}
                                className='cursor-pointer'
                            >
                                {invitationmails.length - 1 === index ? <AddRounded /> : <DeleteIcon />}
                            </div>
                        </div>
                    ))
                }

            </div>

            <div className='flex  items-center gap-5 mt-3'>
                <PrimaryButton disabled={continueLoader} text={continueLoader ? 'Inviting...' : 'Continue'} className='w-[150px] font-[600] ' onClick={handleContinue} />
                <SecondaryButton disabled={skipLoader} text={skipLoader ? 'Logging in...' : 'Skip'} className='w-[150px]  border border-gray-200 rounded-lg' onClick={handleSkip} />
            </div>

        </ScreenLayer>
    )
}
