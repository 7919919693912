import React from 'react';
import SearchIcon from '../../icons/Search';
import CircularLoader from '../Loader/CircularLoader';
import CrossIcon from '../../icons/CrossIcon'; // Make sure to import your cross icon

function Search({ value = '', onChange = null, onSearchIconClick = null, className = '', loading, crossIcon=false }) {
  const handleChange = (event) => {
    onChange(event.target.value.replace(/^\s+/, ''));
  };

  const clearInput = () => {
    onChange(''); // Reset the input field
  };

  return (
    <div className={`border border-silver gap-2 shadow-sm pl-3 p-2 rounded-md flex items-center relative ${className}`}>
      {loading ? (
        <CircularLoader className='w-[20px]' />
      ) : (
        <SearchIcon onClick={onSearchIconClick} />
      )}
      <input
        type="text"
        value={value}
        onChange={handleChange}
        placeholder="Search"
        className='w-full focus-visible:outline-none pr-8' // Added padding for the cross icon
      />
      {value && crossIcon && ( // Only show the cross icon if there's a value
        <div onClick={clearInput} className="absolute right-3 cursor-pointer">
          <CrossIcon className='w-[20px]' /> {/* Adjust the size as needed */}
        </div>
      )}
    </div>
  );
}

export default Search;
