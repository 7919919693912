import React from 'react';

function FlagIcon() {
    return (
        <svg
            width="300"
            height="300"
            viewBox="0 0 160 160"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <mask
                id="mask0"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="160"
                height="160"
            >
                <rect width="160" height="160" fill="url(#paint0_radial)" />
            </mask>
            <g mask="url(#mask0)">
                <circle cx="50" cy="50" r="34" stroke="#EAECF0" />
                <circle cx="50" cy="50" r="50" stroke="#EAECF0" />
                <circle cx="50" cy="50" r="68.5" stroke="#EAECF0" />
                <circle cx="50" cy="50" r="88.5" stroke="#EAECF0" />
                <circle cx="50" cy="50" r="109.5" stroke="#EAECF0" />
            </g>
            <path
                d="M30.0266 14.1944H41.934C42.8784 14.1944 43.3504 14.1944 43.6266 14.3929C43.8674 14.5662 44.0244 14.8328 44.0588 15.1275C44.0982 15.4653 43.869 15.8781 43.4104 16.7035L40.5686 21.8186C40.4022 22.118 40.3192 22.2678 40.2866 22.4262C40.2576 22.5666 40.2576 22.7112 40.2866 22.8516C40.3192 23.0101 40.4022 23.1599 40.5686 23.4593L43.4104 28.5744C43.869 29.3998 44.0982 29.8126 44.0588 30.1504C44.0244 30.4452 43.8674 30.7118 43.6266 30.8851C43.3504 31.0836 42.8784 31.0836 41.934 31.0836H27.071C25.8886 31.0836 25.2974 31.0836 24.8458 30.8532C24.4486 30.6508 24.1256 30.3278 23.9232 29.9306C23.6932 29.479 23.6932 28.8878 23.6932 27.7054V22.6388M16.3043 43.75L7.85986 9.9722M11.0266 22.6388H26.6488C27.8312 22.6388 28.4224 22.6388 28.8738 22.4084C29.271 22.206 29.594 21.883 29.7964 21.4858C30.0266 21.0342 30.0266 20.4429 30.0266 19.2611V9.12778C30.0266 7.94538 30.0266 7.35417 29.7964 6.90256C29.594 6.50536 29.271 6.18236 28.8738 5.97996C28.4224 5.7496 27.8312 5.7496 26.6488 5.7496H11.1304C9.65578 5.7496 8.91846 5.7496 8.41416 6.05556C7.97214 6.32336 7.64374 6.74398 7.49114 7.23774C7.31702 7.8011 7.49586 8.51642 7.85352 9.94704L11.0266 22.6388Z"
                stroke="#344054"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
                transform="scale(1.1) translate(20, 20)"
            />
            <defs>
                <radialGradient
                    id="paint0_radial"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(100 100) rotate(90) scale(350 350)"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="white" stopOpacity="0" />
                </radialGradient>
            </defs>
        </svg>
    );
}

export default FlagIcon;
