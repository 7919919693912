import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./CommissionGalleries.css";

import LoadingWrapper from "./loadingWrapper/LoadingWrapper";
import CustomImage from "./CustomImage/CustomImage";
import { getCommissionImages } from "../services/brandServices";
import useDebounce from "../utils/debounce";
import ImageCardActions from "./ImageCardActions/ImageCardActions";
import SearchGroup from "./searchGroup/SearchGroup";
import VideoPlayer from "./video/player/VideoPlayer";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

function CommissionGalleries() {
  const [imageData, setImageData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [searchText, setSearchText] = useState("");
  const [favFilterState, setFavFilterState] = useState("all")
  const [imageTypeFilter, setImageTypeFilter] = useState("art")
  const query = useQuery();
  const commissionid = query.get("commissionid");
  const commissionname = query.get("commissionname");
  const navigate = useNavigate();

  const handleImageClick = (image) => {
    navigate("/image-details", { state: { image,dataType:"content", commissionname, commissionid } });
  };


  // Divide images into 3 columns
  const columns = [[], [], []];
  imageData.forEach((data, index) => {
    columns[index % 3].push({ url: data.url, data: { ...data, tags: data.tags?.split(',') || [] } });
  });

  const debounceSearchText = useDebounce(searchText, 500)

  useEffect(() => {

    const fetchCommissionContents = async () => {
      try {
        setIsLoadingMore(true)
        let result = await getCommissionImages(commissionid, debounceSearchText, favFilterState, imageTypeFilter)
        setImageData(result?.data || [])
      } catch (error) {
        console.log("error", error)
      } finally {
        setIsLoading(false)
        setIsLoadingMore(false)
      }
    }

    fetchCommissionContents()

  }, [commissionid, debounceSearchText, favFilterState, imageTypeFilter])

  const handleFilterChange = (e) => {
    setFavFilterState(e.target.value)
  }

  const handleImageTypeChange = (e) => {
    setImageTypeFilter(e.target.value)
  }

  return (
    <div className="container-gallery">
      <div className="heading-content">
        {/* <img
          alt="swiirl-logo"
          className="campaign-name-image"
          src="/Commission/campaign-name.png"
        /> */}
        <div className="campaign-name">{commissionname}</div>
      </div>
      <SearchGroup
        favFilterState={favFilterState}
        handleFilterChange={handleFilterChange}
        handleImageTypeChange={handleImageTypeChange}
        imageTypeFilter={imageTypeFilter}
        searchText={searchText}
        setSearchText={setSearchText}
        searchLoading={isLoadingMore}

      />

      <div className="image-container1 p-5">
        <LoadingWrapper loading={isLoading}>
          {imageData?.length ? columns.map((column, colIndex) => (
            <div className="column" key={colIndex}>
              {column.map(({ url, data }, index) => (
                <div
                  key={index}
                  className="image-item flex flex-col"
                >
                  {/* <div className="image-container1" style={{ minHeight: '400px' }} key={index}> */}
                  {
                    data.media_type == "video" ?
                      <VideoPlayer
                        key={data?.alt || ''}
                        src={data.thumbnail_url || ''}
                        alt={data?.alt || "Image"}
                        imageClass="card-image"
                        // cacheKey={data?.thumbnailUrl || ''}
                        onImageClick={() => handleImageClick(data)}
                        shouldPlay={false}
                        videoUrl={data?.url}
                      >
                        <ImageCardActions
                          onAiClick={() => handleImageClick(data)}
                          downLoadImage={true} cardData={{ ...data, url }} />
                      </VideoPlayer>
                      :
                      <CustomImage
                        key={data.s3_key}
                        src={data.thumbnail_url || data.url}
                        alt={data.alt || "Image"}
                        imageClass="card-image"
                        // cacheKey={data.s3_key}
                        onImageClick={() => handleImageClick(data)}
                      >
                        <ImageCardActions
                          onAiClick={() => handleImageClick(data)}
                          downLoadImage={true} cardData={{ ...data, url }} />
                      </CustomImage>
                  }
                  
                  {/* </div> */}

                  {/* <CustomImage
                    src={url}
                    alt={data?.alt || 'img'}
                    imageClass="item-image"
                    skeltonClass="item-image item-image-skelton"
                    cacheKey={data.content_key}
                  // onImageClick={() => handleImageClick(data)}
                  >
                    <ImageCardActions
                      onAiClick={() => handleImageClick(data)}
                      downLoadImage={true} cardData={{ ...data, url }} />
                  </CustomImage> */}

                  {/* {data.tags && data.tags.length > 0 && (
                    <ul className="list-inline">
                      {data.tags.map((tag, tagIndex) => (
                        <li key={tagIndex} className="list-inline-item my-1">
                          <span className="tags">{tag}</span>
                        </li>
                      ))}
                    </ul>
                  )} */}
                </div>
              ))}
            </div>
          )) : <div className="py-24 text-center w-full"><p>No image related to {searchText} found</p></div>
          }
        </LoadingWrapper>
      </div>
    </div>
  );
}

export default CommissionGalleries;
