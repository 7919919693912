import axios from "axios";
import { GetWithToken, PatchWithToken, Post, PostWithToken, PutWithToken } from "../lib/axios";
// const baseUrl = 'https://l26dkbzcng.execute-api.us-east-2.amazonaws.com/brand';
const baseUrl = process.env.REACT_APP_API_URL + "/brand";



export const getBrandServer = async () => {
    try {
        const res = await GetWithToken({endpoint:'/brand'})
        return res
    } catch (error) {
        console.log(error)
    }
}


// export const signup = async ({ email,fullName,role, cognito_id ,signupMethod="editor",invitedBy=null}) => {
//     try {
//         const res = await Post({endpoint: '/user/signup', data: { email,fullName,role, cognito_id,signupMethod,invitedBy }})
//         return res.data
//     } catch (error) {
//         console.log(error)
//         throw error
//     }
// }

export const signup = async (payload) => {
    try {
        const res = await Post({endpoint: '/user/signup', data: { ...payload}})
        return res.data
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const login = async (payload) => {
    try {
        const res = await Post({endpoint: '/user/login', data: { ...payload}})
        return res.data
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const sendInvitationMailToBrandUsers = async ({ payload}) => {
    try {
        const res = await PostWithToken({endpoint:'/brand/send-invite-mail',data:payload})
        return res.data
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const editBrandUser = async (brandUser) => {
    try {
        const res = await PatchWithToken({endpoint:'/brand/update',data:{brandUser}})
        return res
    } catch (error) {
        console.log(error)
        throw error
    }
}
export const editBrand = async (brand) => {
    try {
        const res = await PatchWithToken({endpoint:'/brand/update',data:{brand}})
        return res
    } catch (error) {
        console.log(error)
        throw error
    }
}


export const generateAiData   = async ({ type="gallery", gallery_content_id, content_id  }) => {
    try {
        // const res = await PostWithToken({endpoint:'/brand/generate-ai-data',data:{ type,gallery_content_id, content_id }})
        const res = await PostWithToken({endpoint:'/openai/generate-ai-data',data:{ type,gallery_content_id, content_id }})
        return res
    } catch (error) {
        console.log(error)
    }
}



// export const login = async ({ jwtToken ,cognitoId,loginType}) => {
//     try {
//         const res = await Post({endpoint: '/brand/login', data:  { jwtToken,loginType,cognitoId }})
//         return res.data
//     } catch (error) {
//         console.log(error)
//     }
// }



export const getUploadUrl = async (keyNames,isGetUrl=false) => {
    try {
        const res = await PostWithToken({ endpoint:'/brand/upload-image',data:{ keyNames ,isGetUrl}})
        return res?.data

    } catch (error) {
        console.log(error)
    }
}



export const uploadFileTos3 = async (url, file) => {
    try {
        let response = await axios.put(url, file, {
            headers: {
                'Content-Type': file.type,
                'Authorization': undefined,
                "access-control-allow-origin": "*"
            }
        })
        return response

    } catch (error) {
        console.error(error)
        throw error
    }
}




export const createCommission = async ({ id, brand_id, name, target_location, content_usage, media_type, campaign_goal, creative_design, themes_prompts, links, status, start_date, end_date, files, videoFiles }) => {
    try {
        const res = await PostWithToken({endpoint:'/brand/create-commission', data:{ id, brand_id, name, target_location, content_usage, media_type, campaign_goal, creative_design, themes_prompts, links, status, start_date, end_date, files, videoFiles }})
        return res
    } catch (error) {
        console.log(error)
    }
}



export const validateToken = async ({ payload }) => {
    try {
        const res = await PostWithToken({endpoint:'/token', data:{ payload, action:'validate' }})
        return res
    } catch (error) {
        console.log(error)
    }
}






export const getAllCommission = async ({ user_id, page, limit, dateRanges, status }) => {
    try {

        let qs = ''
        if(page){
            qs+=`${'?page='+page}`
        }
        if(limit){
            qs+=`${'&limit='+limit}`
        }
        if(status){
            qs+=`${'&status='+status}`
        }
        if(dateRanges?.startDate){
            qs+=`${'&startDate='+dateRanges?.startDate}`
        }
        if(dateRanges?.endDate){
            qs+=`${'&endDate='+dateRanges?.endDate}`
        }

        let response = await GetWithToken({endpoint:`/brand/get-all-commission/${user_id}${qs}`})

        return response.data

    } catch (error) {
        console.log(error)
        throw error
    }
}
export const getAllCommissionWithImages = async (user_id) => {
    try {

        let response = await GetWithToken({baseUrl:"http://localhost:4300",endpoint:`/brand/get-commissions-with-contents/${user_id} `})
        return response.data
    } catch (error) {
        console.log(error)
        throw error
    }
}
export const getCommissionImages = async (commission_id, search, favoriteFilter, imageTypeFilter) => {

    try {
        let response = await GetWithToken({baseUrl:"http://localhost:4300",endpoint:`/brand/get-commission-content/${commission_id}${search ?`?tags=${search}`:"?"}&favoriteFilter=${favoriteFilter}&imageTypeFilter=${imageTypeFilter} `})
        return response.data
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const getGalleryContents = async ({ limit, offset, search, brand_id, favoriteFilter, imageTypeFilter }) => {
    try {
        let response = await GetWithToken({baseUrl:"http://localhost:4300",endpoint:`/brand/get-gallery-contents?brand_id=${brand_id}&limit=${limit}&offset=${offset}&search=${search}&favoriteFilter=${favoriteFilter}&imageTypeFilter=${imageTypeFilter}`})
        
        return response.data
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const saveFavorites = async (payload) => {
    try {
        const { data } = await PostWithToken({endpoint:`/brand/favourite`,data: {...payload}})
        return data;
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const purchaseContentByBrand = async (payload) => {
    try {
        const { data } = await PostWithToken({endpoint:`/brand/purchase-content`,data: {...payload} })
        return data;
    } catch (error) {
        console.log(error)
        throw error;
    }
}
export const getPurchaseContentByBrand = async ({ brand_id, favoriteFilter, imageTypeFilter, search, limit, offset }) => {
    try {
        const { data } = await GetWithToken({endpoint:`/brand/purchased-contents?brand_id=${brand_id}&favoriteFilter=${favoriteFilter}&imageTypeFilter=${imageTypeFilter}&search=${search}&limit=${limit}&offset=${offset}`})

        return data;
    } catch (error) {
        console.log(error)
        throw error;
    }
}


export const getAllBrands = async ({ limit, offset = 0, search }) => {
    try {
        const { data } = await GetWithToken({endpoint:`/brand/get-all-brands?limit=${limit}&offset=${offset}&search=${search}`})

        return data;
    } catch (error) {
        console.log(error)
        throw error;
    }
}

export const getBrandProfile = async ({user_id}) => {
    try {
        const { data } = await GetWithToken({endpoint:`/brand/profile/get-brand-profile-by-id?user_id=${user_id}`})

        return data;
    } catch (error) {
        console.log(error)
        throw error;
    }
}

export const updateBrandProfile = async (payload) => {
    try {
        const { data } = await PutWithToken({endpoint:`/brand/profile/update-brand-profile-by-id`,data: {...payload}})

        return data;
    } catch (error) {
        console.log(error)
        throw error;
    }
}
