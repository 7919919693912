import { Post } from "../lib/axios"


export const signup = async (payload) => {
    try {
        const res = await Post({baseUrl:"http://localhost:7000",endpoint: '/user/signup', data: { ...payload}})
        return res.data
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const login = async (payload) => {
    try {
        const res = await Post({
            // baseUrl:'http://localhost:7000',
            endpoint: '/user/login', data: { ...payload}})
        return res.data
    } catch (error) {
        console.log(error)
        throw error
    }
}



export const generateToken = async ({ payload }) => {
    try {
        const res = await Post({endpoint:'/token', data:{ payload, action:'generate' }})
        return res.data
    } catch (error) {
        console.log(error)
    }
}

// baseUrl:"http://localhost:5000",
// baseUrl:"http://localhost:5000",