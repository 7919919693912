import { PROFILE_DATA } from "../actions";

const initialState = {
  isLoggedIn: false,
  username: "",
  token: "", // Add token field for session management
  userId: "",
  isNew: true,
  isPolicyAccepted: false,
  isOwner:0
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      // return {
      //   ...state,
      //   isLoggedIn: true,
      //   username: action.payload.username,
      //   token: action.payload.token, // Save token on login
      //   userId: action.payload.userId,
      //   brandId: action.payload.brandId,
      //   isNew: action.payload.isNew,
      //   isOwner: action.payload.isOwner,
      //   role: action.payload.role,
      // };
      return {
        ...state,
        isLoggedIn: action?.payload?.isLoggedIn ?? true,  // Use action payload if present, otherwise default to true// username: action?.payload?.username,// token: action?.payload?.token, // Save token on login// userId: action?.payload?.userId,// brandId: action?.payload?.brandId,// isNew: action?.payload?.isNew,
        username: action?.payload?.username !== undefined ? action.payload.username?.toLowerCase() : state.username,  // Use payload value if provided, else retain previous state
        token: action?.payload?.token !== undefined ? action.payload.token : state.token,
        userId: action?.payload?.userId !== undefined ? action.payload.userId : state.userId,
        brandId: action?.payload?.brandId !== undefined ? action.payload.brandId : state.brandId,
        schoolId: action?.payload?.schoolId !== undefined ? action.payload.schoolId : state.schoolId,
        creatorId: action?.payload?.creatorId !== undefined ? action.payload.creatorId : state.creatorId,
        isNew: action?.payload?.isNew !== undefined ? action.payload.isNew : state.isNew,
        userRole: action?.payload?.userRole !== undefined ? action.payload.userRole : state.userRole,
        fullName: action?.payload?.fullName !== undefined ? action.payload.fullName : state.fullName,
        isOwner: action?.payload?.isOwner !== undefined ? action.payload.isOwner : state.isOwner,
        role: action.payload.role !== undefined ? action.payload.role : state.role,
        userType: action.payload.userType !== undefined ? action.payload.userType : state.userType,
        isPolicyAccepted: action.payload.isPolicyAccepted !== undefined ? action.payload.isPolicyAccepted : state.isPolicyAccepted,
      };
    case "SIGNUP_SUCCESS":
      return {
        ...state,
        isLoggedIn: action?.payload?.isLoggedIn ?? true
      }
    case "LOGOUT":
      return {
        ...state,
        isLoggedIn: false,
        username: "",
        token: "", // Clear token on logout
        userId: "",
        profileUrl:"",
        profileData:{}
        // profileUrl: "",
      };
    case "PROFILE":
      return {
        ...state,
        profileUrl: action.payload.url,

      };
    case PROFILE_DATA:
      return {
        ...state,
        profileData: action.payload,

      };
    default:
      return state;
  }
};

export default userReducer;
