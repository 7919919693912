import ComingSoon from "../pages/ComingSoon";
import RootLayout from "../pages/Root";
import Settings from "../pages/settings/Settings";

 const  commonRoutes = [
    {
      path: "*",
      element: (
        <RootLayout>
          <ComingSoon />
        </RootLayout>
      ),
    },
    {
      path: "/settings",
      element: (
        <RootLayout>
          <Settings />
        </RootLayout>
      ),
    },
  ];
  

  export default commonRoutes