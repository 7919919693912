import { createSlice } from '@reduxjs/toolkit';
import { getAllCollections } from '../../services/creatorService';
import { getMimeTypeFromFileName } from '../../components/cards/helper';

const LIMIT = 10;

const creatorCollectionSlice = createSlice({
  name: 'creatorCollection',
  initialState: {
    data: [],
    hasMore: true,
    startScreen: true,
    isLoading: true,
    error: null,
    query: '', // Tracks the current search query
  },
  reducers: {
    loadStart: (state) => {
      state.isLoading = true;
    },
    loadSuccess: (state, action) => {
      const newData = action.payload.data;
      const data = []
      if(state.data?.[0]?.creator_collection_id === newData?.[0]?.creator_collection_id){
        data.push(...state.data)
      } else {
        data.push(...state.data, ...newData)
      }
      const isSearch = action.payload.isSearch;
      state.startScreen = newData.length === 0 && state.data.length === 0; // Set to false if data is present
      state.data = [...data];
      state.hasMore = newData.length >= LIMIT;
      state.isLoading = false;
      state.error = null;
    },
    loadFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    setSearchQuery: (state, action) => {
      state.query = action.payload;
      state.data = []; // Clear existing data for new search results
      state.hasMore = true; // Reset pagination for new search
    },
    resetData: (state) => {
      state.data = [];
      state.hasMore = true;
      state.isLoading = false;
      state.error = null;
      state.query = ''; // Reset the search query
    },
  },
});

export const { loadStart, loadSuccess, loadFailure, setSearchQuery, resetData } = creatorCollectionSlice.actions;

// Thunk to load data with pagination or search
export const fetchCreatorCollection = ({ page, creatorId, query = '', limit = LIMIT }) => async (dispatch) => {
  dispatch(loadStart());
  try {
    const response = await getAllCollections({ creator_id: creatorId, limit, page, searchText: query });
    response.data.forEach(item => {
      item.images = item.images.map(elm => ({
        ...elm,
        mediaType: getMimeTypeFromFileName(elm.filename)
      }));
    });
    const data = response?.data || [];
    dispatch(loadSuccess({ data, isSearch: query !== '' }));
  } catch (error) {
    dispatch(loadFailure(error.toString()));
  }
};

// Thunk to handle search
export const searchCreatorCollection = ({ query, creatorId }) => (dispatch) => {
  dispatch(setSearchQuery(query));
  dispatch(fetchCreatorCollection({ page: 1, query, creatorId })); // Start from page 1 for new search
};

// New thunk to revalidate data
export const revalidateCreatorCollection = ({ creatorId }) => (dispatch) => {
  dispatch(resetData());
  dispatch(fetchCreatorCollection({ page: 1, creatorId }));
};

export default creatorCollectionSlice.reducer;
