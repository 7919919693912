import PrivateRoute from "../../HOC/PrivateRouteLayout";
import CreatorCollectionsPage from "../../pages/Creator/my-work/show-creator-content/CreatorCollectionsPage";
import UploadCreatorContent from "../../pages/Creator/my-work/upload-creator-content/UploadCreatorContent";
import EditCreatorProfile from "../../pages/Creator/Profile/EditCreatorProfile";
import EditCreatorProfileForCollectionPublish from "../../pages/Creator/Profile/EditCreatorProfileForCollectionPublish";
import ViewCreatorProfile from "../../pages/Creator/Profile/ViewCreatorProfile";
import ViewWork from "../../pages/Creator/Profile/ViewWork";
import CreatorProjectDetails from "../../pages/Creator/project/ProjectDetails";
import ProjectListing from "../../pages/Creator/project/ProjectListing";
import ViewCollection from "../../pages/Creator/ViewCollection/ViewCollection";
import RootLayout from "../../pages/Root";
import SchoolProjectContentForm from "../../pages/school/upload-project-content/SchoolProjectContentForm";
import SchoolSupport from "../../pages/support/SchoolSupport";

const creatorRoutes = [

  {
    path: "/creator",
    element: (
      <PrivateRoute>
        <RootLayout>
          <CreatorCollectionsPage />
        </RootLayout>
      </PrivateRoute>
    ),
  },
  {
    path: "/creator",
    children: [
      {
        path: "collection/:collectionId",
        element: (
          <PrivateRoute>
            <RootLayout>
              <ViewCollection />
            </RootLayout>
          </PrivateRoute>
        ),
      },
      {
        path: "upload",
        element: (
          <PrivateRoute>
            <RootLayout>
              <UploadCreatorContent />
            </RootLayout>
          </PrivateRoute>
        ),
      },
      {
        path: "profile",
        element: (
          <PrivateRoute>
            <RootLayout>
              <ViewCreatorProfile />
            </RootLayout>
          </PrivateRoute>
        ),
      },
      {
        path: "profile/edit",
        element: (
          <PrivateRoute>
            <RootLayout>
              <EditCreatorProfile />
            </RootLayout>
          </PrivateRoute>
        ),
      },
      {
        path: "profile/edit/publish",
        element: (
          <PrivateRoute>
            <RootLayout>
              <EditCreatorProfileForCollectionPublish />
            </RootLayout>
          </PrivateRoute>
        ),
      },

      {
        path: "profile/work",
        element: (
          <PrivateRoute>
            <RootLayout>
              <ViewWork />
            </RootLayout>
          </PrivateRoute>
        ),
      },

      {
        path: "support",
        element: (
          <PrivateRoute>
            <RootLayout>
              <SchoolSupport />
            </RootLayout>
          </PrivateRoute>
        ),
      },
      {
        path: "projects",
        element: (
          <PrivateRoute>
            <RootLayout>
              <ProjectListing />
            </RootLayout>
          </PrivateRoute>
        ),
      },
      {
        path: "project-details/:commissionXrefId",
        element: (
          <PrivateRoute>
            <RootLayout>
              <CreatorProjectDetails />
            </RootLayout>
          </PrivateRoute>
        ),
      },
      {
        path: "project/upload",
        element: (
          <PrivateRoute>
            <RootLayout>
              <SchoolProjectContentForm />
            </RootLayout>
          </PrivateRoute>
        ),
      },

    ],
  },
];


export default creatorRoutes