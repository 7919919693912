import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getProjectContributors } from '../../services/schoolServices'
import LoadingWrapper from '../loadingWrapper/LoadingWrapper'

function ContributorTabItem({
    commissionXrefId,
    disableContributorsButton
}) {
    const [contributorData, setContributorData] = useState([])
    const [loading, setLoading] = useState(false)

    async function fetchContributors() {
        try{
            setLoading(true)
            const { data } = await getProjectContributors({ commissionXrefId })
            setContributorData(data)
        } catch(e) {
            console.log(e)
        } finally{
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchContributors()
    }, [])
    const navigate = useNavigate()
    return (
        <div className="tab-content">
            <LoadingWrapper loading={loading}>
                <span
                    onClick={() => !disableContributorsButton && navigate('/school/invite-contributors', { state: { commissionXrefId } })}
                    className={`${disableContributorsButton ? "text-slate-400  cursor-not-allowed" : "text-[#6941C6]  cursor-pointer"} font-inter font-semibold text-[16px] leading-[24px]`}
                >
                    + Invite contributors
                </span>
                <p className="text-[#475467] font-inter font-normal text-[16px]">Invite artists you would like to participate in this project</p>
                <div className='flex flex-row gap-4 mt-4 flex-wrap'>
                    {
                        contributorData?.contributors?.map((contributor,i) => (
                            <>
                            <p key={i} className="text-lg text-slate-700">{contributor.email}</p>
                            </>
                        ))
                    }
                </div>
            </LoadingWrapper>
        </div>
    )
}

export default ContributorTabItem