import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getAllCommission, getAllCommissionWithImages, getPurchaseContentByBrand } from '../../services/brandServices';
import LoadingWrapper from '../../components/loadingWrapper/LoadingWrapper';
import CommissionListing from '../../components/CommissionListing';
import CustomImage from '../../components/CustomImage/CustomImage';
import ImageCardActions from '../../components/ImageCardActions/ImageCardActions';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import NoCommission from '../../components/cards/NoCommission';
import BoyWithCamaraIcon from '../../icons/BoyWithCamar';
import YouHaventPurchasedAnythingIcon from '../../icons/YouHaventPurchasedAnything.png';
import CommissionTabView from '../../components/CommissionTabView/CommissionTabView';
import useDebounce from '../../utils/debounce';
import SearchGroup from '../../components/searchGroup/SearchGroup';
import VideoPlayer from '../../components/video/player/VideoPlayer';
import EditIcon from '../../icons/Edit';




function MyContent() {
    const [commissionData, setCommissionData] = useState([]);
    const [purchasedItems, setPurchasedItems] = useState([]);
    const [pendingCommission, setPendingCommission] = useState(null);
    const [purchaseLoading, setPurchaseLoading] = useState(false)
    const [searchText, setSearchText] = useState("");
    const [loading, setLoading] = useState(false)
    const [favFilterState, setFavFilterState] = useState("all")
    const [imageTypeFilter, setImageTypeFilter] = useState("art")
    const [page, setPage] = useState(1)
    const debounceSearchText = useDebounce(searchText, 500)
    const { username, userId, brandId, profileUrl } = useSelector((state) => state.user);
    const navigate = useNavigate()
    const [tab, setTab] = useState(0)
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const contentType = queryParams.get('content');
    const [showViewMore, setShowViewMore] = useState(true)
    const [isLoadingMore, setIsLoadingMore] = useState(false)



    useEffect(() => {
        const fetchPendingCommission = async () => {
            try {
                const data = await getAllCommission({ user_id: brandId, page: 1, limit: 1, status: 'in_progress' })
                setPendingCommission(data?.data?.length > 0 ? data?.data[0] : null)
            } catch (error) {
                console.log(error)
            }
        }

        const fetchAllCommission = async () => {
            try {
                const data = await getAllCommissionWithImages(brandId);
                setCommissionData(data.data);
            } catch (error) {
                console.log(error);
            }

        }
        const waitForAllFunction = async () => {
            try {
                setLoading(true)
                await Promise.all([fetchPendingCommission(), fetchAllCommission()])
            } catch (err){
                console.log(err)
            } finally {
                setLoading(false)
            }
        }
        waitForAllFunction()
    }, [brandId])


    const fetchPurchasedItems = async ({clearData=false,pg=page}) => {
        setIsLoadingMore(true)
        const limit = 10
        const offset = (limit * pg) - limit
        setPurchaseLoading(true)
        try {
             
            const data = await getPurchaseContentByBrand({ brand_id: brandId, search: debounceSearchText, favoriteFilter: favFilterState, imageTypeFilter, limit, offset });
            setShowViewMore(data?.result.length < limit ? false : true);
            if(clearData) {
                setPurchasedItems(data?.result);
            } else {
                setPurchasedItems([...purchasedItems, ...data?.result] || []);
            }
        } catch (error) {
            console.log(error);
             
        } finally {
            setIsLoadingMore(false)
            setPurchaseLoading(false)
             

        }
    }

    useEffect(() => {
        fetchPurchasedItems({})
    }, [brandId, debounceSearchText, imageTypeFilter])

    useEffect(()=>{
        setPage(1)
        setPurchaseLoading(true)
        fetchPurchasedItems({clearData:true,pg:1})
    },[favFilterState])

    const handlePageChange = () => {
        const pg = page +1
        setPage(pg)
        fetchPurchasedItems({pg})
    }


    useEffect(() => {
        // this is to avoid showing "No purchase item banner" if the user search for some keyword which donot have item
        // and then clear the search text (there will be a delay to trigger fetch purchase function and show loader due to debouncing)

        if (searchText === "") setPurchaseLoading(true)
        setShowViewMore(true)
        setPurchasedItems([])
    }, [searchText])

    useEffect(() => {
        if (contentType == "purchase") setTab(1)
    }, [contentType])

    const handleTab = (e) => {
        if (e.target.id === '0') setTab(0)
        else if (e.target.id === '1') setTab(1)
    }

    const handleImageClick = (image) => {
        navigate(`/image-details`, { state: { image, hidePurchasButton: true } })

    }
    const handleFilterChange = (e) => {
        setFavFilterState(e.target.value)
    }

    const handleImageTypeChange = (e) => {
        setImageTypeFilter(e.target.value)
    }

    const hasCommission = commissionData && commissionData.length > 0;
    const hasMorethanOneCommission = commissionData && commissionData.length > 1;
    const hasPurchases = purchasedItems && purchasedItems.length > 0;


    const columns = [[], [], []]; // Three columns

    // Distribute images equally among the columns
    purchasedItems.forEach((image, index) => {
        columns[index % 3].push(image);
    });
    return (
        <div className='content-area '>
            <div className='row m-0'>
                <div className="col-lg-12 px-0 ">
                    <div className="h-[185px] relative">
                        <img
                            src="/home/header.png"
                            alt="Home Page Banner"
                            className="w-full max-h-full min-h-full object-cover"
                        />
                        <div className="rounded-full absolute z-10 overflow-hidden top-[98px] w-[174px] h-[174px] left-[50px] ">
                            {
                                profileUrl?.length ?
                                    <img src={profileUrl} className="w-full h-full rounded-full object-cover" alt="Circle Page Banner" />
                                    :
                                    <img src="/home/circle2.png" className="max-w-[100%] scale-[103%] object-cover" alt="Circle Page Banner" />
                            }
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 pt-3 custom-max-width">

                    <div className="row align-items-center mb-24">
                        <div className="col-lg-12 flex justify-between ">
                            <div className='pl-[225px]'><p className='text-[30px] font-[600] text-primary-black'>{username}</p></div>
                            <div className="button-placement pt-2">
                                <Link to="/shop" style={{ textDecoration: "none" }}>
                                    <button className="button-secondary-sw text-primary-black">
                                        View Galleries
                                    </button>
                                </Link>
                                <Link
                                    to="/commissions/create"
                                    style={{ textDecoration: "none" }}
                                >
                                    <button className="button-primary-sw ">New Commission</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='px-3 custom-max-width'>
                    <LoadingWrapper loading={loading}>
                        {/* if there is a pending commissio we show here, if there is no pending commission we show latest commission here */}
                        {
                            pendingCommission ? <div className='px-[40px]'>
                                <CommissionTabView commissionToDisplay={pendingCommission} fromContentPage />
                            </div> :
                                hasCommission &&
                                <div className='pb-[100px]'>
                                    <CommissionListing isLatestCommission commissions={[commissionData[0]]} />
                                </div>
                        }

                        <div>
                            <div className='px-[40px]'>
                                <p className='text-[28px] font-[600]'>Your content</p>
                                <div className='flex justify-end text-[16px] font-[600] mb-5' onClick={handleTab}>
                                    <div id='0' className={`${tab === 0 ? 'text-violet custom-border-b-violet' : 'text-silver custom-border-b-silver '} cursor-pointer py-2 font-[600] `}>Commissioned collections</div>
                                    <div className='w-[30px] custom-border-b-silver'></div>
                                    <div id='1' className={`${tab === 1 ? 'text-violet custom-border-b-violet' : 'text-silver custom-border-b-silver '} cursor-pointer py-2 font-[600]`}>Purchases</div>
                                </div>
                            </div>
                            {tab === 0 && <div>
                                {(hasMorethanOneCommission||hasCommission) ? <CommissionListing commissions={commissionData?.slice(pendingCommission ? 0 : 1) || []} /> : // if there is pending commission we are showing pending commission in top section,
                                    // so we need to show all the active commission here (so slice(0)) other wise latest commission show on top  and rest of the commissoin show here (slice(1))
                                    <div className='flex flex-col items-center py-24'>
                                        <BoyWithCamaraIcon />
                                        <NoCommission 
                                        Icon={EditIcon} 
                                        mainText="You do not have any art commissioned yet." 
                                        supportingText="Dive into the editor and start creating"    
                                        />
                                    </div>
                                }
                            </div>}
                            {tab === 1 && <div className='px-[40px]'>
                                {/* search and dropdowns */}
                                    <SearchGroup
                                            favFilterState={favFilterState}
                                            handleFilterChange={handleFilterChange}
                                            handleImageTypeChange={handleImageTypeChange}
                                            imageTypeFilter={imageTypeFilter}
                                            searchText={searchText}
                                            setSearchText={setSearchText}
                                            searchLoading={purchaseLoading}
                                            className='mb-4'

                                        />
                                {(hasPurchases || searchText !== "") ?
                                    
                                    <LoadingWrapper loading={purchaseLoading}>

                                        {hasPurchases ?
                                            <div className=''>

                                                <div className="parent ">

                                                    {columns.map((column, colIndex) => (
                                                        <div className="column" key={colIndex}>
                                                            {column.map((media, index) => (
                                                                <div className="image-container1" style={{ minHeight: '400px' }} key={index}>
                                                                    {
                                                                        media.media_type === 'video' ?

                                                                            <VideoPlayer
                                                                                key={media?.s3_key || ''}
                                                                                src={media?.thumbnailUrl || ''}
                                                                                alt={media?.description || "Image"}
                                                                                imageClass="card-image"
                                                                                cacheKey={media?.thumbnailUrl || ''}
                                                                                onImageClick={() => handleImageClick(media)}
                                                                                videoUrl={media?.url}
                                                                            >
                                                                                <ImageCardActions cardData={media} downLoadImage />
                                                                            </VideoPlayer>
                                                                            :
                                                                            <CustomImage
                                                                                src={media.url}
                                                                                alt={media.description || "Image"}
                                                                                imageClass="card-image"
                                                                                cacheKey={media.s3_key}
                                                                                onImageClick={() => handleImageClick(media)}
                                                                            >
                                                                                <ImageCardActions cardData={media} downLoadImage />
                                                                            </CustomImage>
                                                                    }
                                                                </div>
                                                            )
                                                            )}
                                                        </div>
                                                    ))}

                                                </div>

                                                {
                                                    showViewMore &&
                                                    <div className="flex justify-center mt-4 ">
                                                        <button onClick={handlePageChange} disabled={isLoadingMore} className="custom-border-violet rounded-lg px-3 py-2 text-violet">{isLoadingMore ? "loading..." : "View more"}</button>
                                                    </div>
                                                }

                                            </div>
                                            :
                                            <div className='flex justify-center py-24'>
                                                <p> No item found related to {searchText}</p>
                                            </div>
                                        }
                                    </LoadingWrapper> :
                                    <LoadingWrapper loading={purchaseLoading} >
                                        <div className='flex justify-center gap-6 py-24'>
                                            <img src={YouHaventPurchasedAnythingIcon} alt='' />
                                            <div className='flex flex-col w-[250px] gap-[30px]'>
                                                <p className='text-[20px] font-[600] text-primary-black'>You haven’t purchased anything yet</p>
                                                <p className='text-[16px] text-[#475467]'>Browse amazing work from our emerging creators! </p>
                                                <Link to="/shop" style={{ textDecoration: "none" }}>
                                                    <button className='px-3 py-2 custom-border-violet text-violet rounded-lg text-[16px] font-[600] w-fit'>Shop now</button>
                                                </Link>
                                            </div>
                                        </div>
                                    </LoadingWrapper> 
                                        
                                }
                            </div>}
                        </div>

                    </LoadingWrapper>
                </div>
            </div>
        </div>
    )
}

export default MyContent