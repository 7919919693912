import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import InputText from '../../../components/FormComponets/InputText'
import TextArea from '../../../components/FormComponets/TextArea'
import CloudUploadIcon from '../../../icons/CloudUpload'
import AddRounded from '../../../icons/AddRounded'
import DeleteIcon from '../../../icons/Delete'
import CrossIcon from '../../../icons/CrossIcon'
import { useDispatch, useSelector } from 'react-redux'
import { editUserProfile, getUserProfile } from '../../../services/creatorService'
import { getUploadUrl, uploadFileTos3 } from '../../../services/brandServices'
import { ulid } from "ulid"
import { isValidUrl, removeQueryStringIfValidUrl } from '../../../utils/urlUtils'
import GrayCameraIcon from '../../../icons/GrayCameraIcon'
import { loginSuccess, profile } from '../../../redux/actions'
import {profileData as ProfileDataDispatcher} from '../../../redux/actions'
import useToast from '../../../hooks/useToast'
import { formatName } from '../../../utils/formatName'

const EditCreatorProfile = () => {
  const showToast=useToast()
  const navigate = useNavigate()
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [alias, setAlias] = useState('')
  const [title, setTitle] = useState('')
  const [location, setLocation] = useState('')
  const [bio, setBio] = useState('')

  const [fullName, setFullName] = useState('')
  const [skillValue, setSkillValue] = useState('')


  const [selectedImg, setSelectedImg] = useState(null);
  const [profileImgUrl, setProfileImgUrl] = useState("");
  const [file, setFile] = useState(null)
  const [s3Key, setS3Key] = useState('')
  const [socialMediaLinks, setSocialMediaLinks] = useState([""])
  const [skills, setSkills] = useState([])

  const dispatch = useDispatch()
  const [isImageUploading, setIsImageUploading] = useState(false)
  const [loading, setLoading] = useState(false)

  const { userId, profileData } = useSelector(state => state.user);


  const reset = () => {
    setFirstName("")
    setLastName("")
    setAlias("")
    setTitle("")
    setLocation("")
    setBio("")
    setSelectedImg("")
    setProfileImgUrl("")
    setSocialMediaLinks([""])
    setSkills([])


  }





  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if(!file?.type?.includes('image')){
      showToast('Only jpg, jpeg, svg, png or gif are accepted',"error")
      return 
    }
    if (file) {
      setFile(file)
      setSelectedImg(URL.createObjectURL(file));
    }
  };

  const getSignedUrlAndUploadImage = async (file) => {
    setIsImageUploading(true)
    let response = [];

    if (!file) {
      setIsImageUploading(false)
      alert("please select  image.")

      return response
    };


    const key = `profile/creator/${userId}/${ulid()}`;

    const { urls } = await getUploadUrl([key]);
    response = { name: file.name, key }

    // await Promise.all(
    await uploadFileTos3(urls[0], file)
    // )
    let payload;
    setS3Key(key)
    if (key) {
      payload = {creator_id: userId || "", profile_pic_key: key }
    }
     
    
    const resp = await editUserProfile(payload)
    const userData = await fetchUserProfile(true)
    dispatch(profile(userData?.profile_pic_url || ""));
    dispatch(ProfileDataDispatcher(userData));
    showToast('Profile image updated successfully.',"success")

    setIsImageUploading(false)
  };

  const handleSubmit = async () => {
    const modifiedLinks = []
    socialMediaLinks.forEach(url => {
      try {
        const cleanUrl = removeQueryStringIfValidUrl(url);
        modifiedLinks.push(cleanUrl)
      } catch (error) {
        console.error(error.message);
      }
    });
    setLoading(true)
    let payload = {
      location: location || "",
      bio: bio || "",
      // profile_pic_key: s3Key || "",
      first_name: firstName || "",
      last_name: lastName || "",
      title: title || "",
      social_links: (modifiedLinks && modifiedLinks.length > 0 && modifiedLinks.join(',')) || "",
      skills: (skills && skills.length > 0 && skills.join(',')) || "",
      alias: alias || "",
      creator_id: userId || ""
    }




    const resp = await editUserProfile(payload)
    dispatch(loginSuccess({fullName: firstName + " "+ lastName}))
    setLoading(false)
    showToast('Profile updated successfully.',"success")

    navigate('/creator/profile')
  }


  async function fetchUserProfile (shouldCallApi=false) {
    try {
      let response,userData;
      if(shouldCallApi){
        response = await getUserProfile({creator_id:userId});
        userData = response.data
      } else {
        userData = profileData
      }
      setFullName(formatName(userData?.first_name, userData?.last_name))
      setFirstName(userData?.first_name)
      setLastName(userData?.last_name)
      setAlias(userData?.alias)
      setTitle(userData?.title)
      setLocation(userData?.location)
      setBio(userData?.bio)
      // setSelectedImg(userData?.profile_pic_key)
      setProfileImgUrl(userData?.profile_pic_url)
      setSocialMediaLinks((userData?.social_links)?.split(',') || [""])
      setSkills((userData?.skills)?.split(',') || [])
      return userData
    } catch (error) {
      console.log(error)
    } finally {
      // setLoading(false)
    }
  }

  useEffect(() => {
    fetchUserProfile();
  }, [userId])

  const shortText = (text) => {
    return ((text.length > 7) ? text.slice(0,7) + "..." : text);
  }

  return (
    <div className="content-area">
      <div className="row m-0 ">
        <div className="col-lg-12 px-0 ">
          <div className="h-[185px] relative">
            <img
              src="/creator/banner_img.png"
              alt="Home Page Banner"
              className="w-full max-h-full min-h-full object-cover"
            />
            {/* <div className="rounded-full absolute z-10 overflow-hidden top-[128px] w-[174px] h-[174px]  left-[50px] border-1">
              <img src={profileImgUrl || "/home/circle2.png"} className="max-w-[100%] scale-[103%] object-cover" alt="Circle Page Banner" />
            </div> */}
            <div className="rounded-full absolute z-10 overflow-hidden top-[128px] w-[174px] h-[174px] left-[50px] border-1s bg-gray-400s">

              {profileImgUrl ? <img src={ profileImgUrl || "/home/circle2.png"} className="w-full h-full object-cover" alt="__" /> : <GrayCameraIcon size={174} />}
            </div>
          </div>
        </div>

        <div className="col-lg-11 mt-3 mb-5 custom-max-width">
          <div className="row align-items-center">
            <div className="col-lg-12 ">
              <div className="flex justify-between gap-4">

                <div className='pl-[247px]'>
                  {/* <div className='text-[30px] font-[600] text-primary-black'>{fullName || "-"}  </div> */}
                  <div className='text-[30px] font-[600] text-primary-black'>{alias || fullName || firstName}</div>


                  <span className='text-seconday'>{title || "Creator at swiirl"}</span>
                </div>
                <div className=''>
                  <div className="flex gap-3 pt-2">

                    <button className="button-secondary-sw text-primary-black" style={{ width: '80px' }} onClick={() => navigate('/creator/profile')}> Cancel </button>
                    <button className="button-primary-sw " style={{ width: '80px' }} onClick={handleSubmit} disabled={loading}>{loading ? "Saving..." : "Save"}</button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="col-lg-10 custom-max-width  mt-5">
          <div className=' px-[55px]'>
            <div className=' border-b-[1px] py-2  border-text-seconday-gray '>
              <h4 className='font-[600] text-primary-black'>Personal Info</h4>
              <p>Update your photo and personal details here.</p>
            </div>
            <div className=' border-b-[1px]  py-4 flex  border-text-seconday-gray '>
              <h6 className='font-[600] col-3 text-primary-black'>Name</h6>
              <div className=' w-full'>
                <div className='w-[71%] flex gap-5 justify-between'>

                  <div className='w-6/12'>
                    <InputText className=' w-full p-2' value={(firstName)} placeholder='Enter first name' onChange={(e) => setFirstName(e.target.value.replace(/^\s+/, ''))} />
                  </div>
                  <div className='w-6/12'>
                    <InputText className=' w-full p-2' value={lastName} placeholder='Enter last name' onChange={(e) => setLastName(e.target.value.replace(/^\s+/, ''))} />
                  </div>
                </div>
              </div>
            </div>
            <div className=' border-b-[1px] py-4  flex border-text-seconday-gray '>
              <h6 className='font-[600] col-3 text-primary-black'>Alias</h6>
              <div className='flex gap-5 w-full'>
                <div className='w-[71%]'>
                  <InputText className='  w-full p-2' value={alias} placeholder='Enter alias' onChange={(e) => setAlias(e.target.value.replace(/^\s+/, ''))} />
                </div>

              </div>
            </div>
            <div className=' border-b-[1px] py-4  flex border-text-seconday-gray '>
              <div className='col-3'>
                <h6 className='font-[600] col-3 text-primary-black'>Your Photo</h6>
                <div>This will be displayed on your profile.</div>
              </div>
              <div className=' gap-5 w-full '>
                <div className='w-[71%]'>
                  <div className='flex gap-5'>

                    <div>
                      <div className="rounded-full overflow-hidden top-[128px] w-[70px] h-[70px] border-1">
                        {(selectedImg || profileImgUrl) ? <img src={ selectedImg || profileImgUrl || "/home/circle2.png"} className="w-full h-full object-cover" alt="Circle Page Banner" />
                          : <GrayCameraIcon size={70} />
                        }                      </div>
                    </div>

                    <label className="upload-control" htmlFor="upload-input">
                      <input
                        type="file"
                        id="upload-input"
                        accept="image/jpeg, image/png, image/gif, image/jpg,image/svg"

                        onChange={handleImageChange}

                      />
                      <div className="uploadicon p-2 mb-3 rounded-md">
                        {/* <UploadIcon /> */}
                        <CloudUploadIcon />
                      </div>
                      <div className="upload-options">
                        <span className="click">Click to upload
                          {/* <span className='text-gray-500'>or drag and drop</span> */}
                        </span>
                      </div>
                      <div className=" mt-0 p-0 text-gray-500">
                        jpg, jpeg, svg, png or gif
                        {/* Zip or folder */}
                      </div>
                    </label>
                  </div>
                  <div className='flex justify-end'>
                    <div className="flex gap-3 pt-2">
                      <button onClick={()=>navigate(-1)} className="button-secondary-sw text-primary-black" style={{ width: '80px' }}> Cancel </button>
                      <button className="button-primary-sw  " style={{ width: '120px' }} disabled={isImageUploading} onClick={() => getSignedUrlAndUploadImage(file)}>{isImageUploading ? "Uploading..." : "Save Image"}</button>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className=' border-b-[1px] py-4  flex border-text-seconday-gray '>
              <h6 className='font-[600] col-3 text-primary-black'>Title</h6>
              <div className='flex gap-5 w-full'>
                <div className='w-[71%]'>
                  <InputText className=' w-full p-2' value={title} placeholder='Enter title' onChange={(e) => setTitle(e.target.value.replace(/^\s+/, ''))} />
                </div>

              </div>
            </div>
            <div className=' border-b-[1px] py-4  flex border-text-seconday-gray '>
              <h6 className='font-[600] col-3 text-primary-black'> City and State/ Province</h6>
              <div className='flex gap-5 w-full'>
                <div className='w-[71%]'>
                  <InputText className=' w-full p-2' value={location} placeholder='Enter city' onChange={(e) => setLocation(e.target.value.replace(/^\s+/, ''))} />
                </div>

              </div>
            </div>

            <div className=' border-b-[1px] py-4  flex border-text-seconday-gray '>
              <div className='col-3'>
                <h6 className='font-[600]  text-primary-black'>Bio</h6>
                <div>Write a short introduction.</div>
              </div>
              <div className='flex gap-5 w-full'>
                <div className='w-[71%]'>
                  <TextArea className=' w-full min-h-[20vh]' value={bio || ""} placeholder='Enter bio' wordLimit={200} onChange={(e) => setBio(e.target.value.replace(/^\s+/, ''))} />
                </div>

              </div>
            </div>

            <div className=' border-b-[1px] py-4  flex border-text-seconday-gray '>
              <h6 className='font-[600] col-3 text-primary-black'>Social profiles and links</h6>
              <div className=' flex flex-col gap-3 w-full'>
                {/* {
                  socialMediaLinks && [...socialMediaLinks].map((item, index) => {
                    return (<div key={index} className='flex items-center gap-3 '>
                      <div className='w-[71%]  '>
                        <InputText className=' w-full p-2' placeholder='Enter link' />
                      </div>
                      <div onClick={() => setSocialMediaLinks(socialMediaLinks.length - 1 === index ? [...socialMediaLinks, ""] : [...socialMediaLinks.filter((item, ind) => index !== ind)])} className='cursor-pointer'>
                        {socialMediaLinks.length - 1 === index ? <AddRounded /> : <DeleteIcon />}
                      </div>
                    </div>)
                  })
                } */}

                {
                  socialMediaLinks && socialMediaLinks.map((item, index) => (
                    <div key={index} className='flex items-center gap-3'>
                      <div className='w-[71%]'>
                        <InputText
                          className='w-full p-2'
                          placeholder='Enter link'
                          value={item}
                          onChange={(e) => {
                            const updatedLinks = [...socialMediaLinks];
                            updatedLinks[index] = e.target.value;
                            setSocialMediaLinks(updatedLinks);
                          }}
                        />
                      </div>
                      <div
                        onClick={() => {
                          if (socialMediaLinks.length - 1 === index) {
                            setSocialMediaLinks([...socialMediaLinks, ""]);
                          } else {
                            setSocialMediaLinks(socialMediaLinks.filter((_, ind) => ind !== index));
                          }
                        }}
                        className='cursor-pointer'
                      >
                        {socialMediaLinks.length - 1 === index ? <AddRounded /> : <DeleteIcon />}
                      </div>
                    </div>
                  ))
                }



              </div>
            </div>


            <div className=' border-b-[1px] py-4  flex border-text-seconday-gray '>
              <div className='col-3'>
                <h6 className='font-[600]  text-primary-black'>Skills</h6>
              </div>
              <div className='flex gap-5 w-full'>
                <div className='w-[71%] border-1 min-h-[20vh] p-3'>
                  <div className='flex flex-wrap gap-3'>

                    {
                      skills && skills.map((item, index) => {
                        const shortenText = shortText(item.trim());
                        return (
                          shortenText&&
                          <div className='rounded-lg border-1 p-2 flex items-center' key={index}>
                            <span>
                              {shortenText}
                            </span>
                            <span className='w-[20px] ms-2 cursor-pointer  ' onClick={() => setSkills(skills.filter((itesm, ind) => ind !== index))}>
                              <CrossIcon />
                            </span>
                          </div>
                        )
                      })
                    }
                    <input className='outline-none	 border-b-2 '
                      onChange={(e) => setSkillValue(e.target.value.replace(/^\s+/, ''))}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter'&& event.target.value) {
                          setSkills([...skills, event.target.value || ""])
                          setSkillValue('')
                        }
                      }}

                      value={skillValue || ""}

                      placeholder='Enter skill' />
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>



      </div>
    </div>
  )
}

export default EditCreatorProfile

