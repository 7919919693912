import React from 'react';

const PlayIcon = ({ onClick = () => null, duration=null, center=false, playIconClass }) => (
  <div className={`flex flex-row gap-3 items-center text-silver absolute z-50 ${center ? 'top-1/2 right-1/2 translate-x-1/2 -translate-y-1/2 ': playIconClass} `}>
    {
      duration ? 
      <span className='font-extrabold' >{duration}</span>
      :
      null
    }
    <svg className='w-6 h-6' onClick={onClick} width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.2806 6.46383C7.2806 6.02632 7.2806 5.80756 7.37203 5.68543C7.45171 5.57901 7.57367 5.51242 7.70628 5.50295C7.85845 5.49208 8.04246 5.61038 8.41049 5.84696L12.7377 8.62874C13.0571 8.83404 13.2167 8.93669 13.2719 9.06721C13.3201 9.18126 13.3201 9.30995 13.2719 9.424C13.2167 9.55452 13.0571 9.65717 12.7377 9.86247L8.41049 12.6442C8.04246 12.8808 7.85845 12.9991 7.70628 12.9883C7.57367 12.9788 7.45171 12.9122 7.37203 12.8058C7.2806 12.6837 7.2806 12.4649 7.2806 12.0274V6.46383Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1.32227 5.39561C1.32227 3.85546 1.32227 3.08539 1.622 2.49713C1.88565 1.97969 2.30635 1.55899 2.82379 1.29534C3.41205 0.995605 4.18212 0.995605 5.72227 0.995605H13.4223C14.9624 0.995605 15.7325 0.995605 16.3207 1.29534C16.8382 1.55899 17.2589 1.97969 17.5225 2.49713C17.8223 3.08539 17.8223 3.85546 17.8223 5.39561V13.0956C17.8223 14.6358 17.8223 15.4058 17.5225 15.9941C17.2589 16.5115 16.8382 16.9322 16.3207 17.1959C15.7325 17.4956 14.9624 17.4956 13.4223 17.4956H5.72227C4.18212 17.4956 3.41205 17.4956 2.82379 17.1959C2.30635 16.9322 1.88565 16.5115 1.622 15.9941C1.32227 15.4058 1.32227 14.6358 1.32227 13.0956V5.39561Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  </div>
);

export default PlayIcon;
