import axios from "axios";
import { Delete, DeleteWithToken, Get, GetWithToken, PatchWithToken, Post, PostWithToken, PutWithToken } from "../lib/axios";
// const baseUrl = 'https://l26dkbzcng.execute-api.us-east-2.amazonaws.com/brand';
// const baseUrl = process.env.REACT_APP_API_URL + "/creator";
// const baseUrl = 'http://localhost:3000'+"/creator";


export const getCreatorServer = async () => {
    try {
        const res = await Get({endpoint:'/creator'})
        return res
    } catch (error) {
        console.error(error)
        throw error
    }
}


export const creatorSignup = async ({ role, id, first_name, last_name, refferal }) => {
    try {
        let response = await Post({endpoint: `/creator/signup`, data: { role, creator_id: id, first_name, last_name, refferal }})
        return response.data
    } catch (error) {
        console.error(error)
        throw error
    }
}



export const creatorLogin = async ({ jwtToken }) => {
    try {
        let response = await Post({endpoint: `/creator/login`, data: { jwtToken }})
        return response.data
    } catch (error) {
        console.error(error)
        throw error
    }
}


export const creatorCollection = async (payload) => {
    try {
        let response = await PostWithToken({endpoint:`/creator/collection/create`,data: { ...payload }})
        return response.data
    } catch (error) {
        console.error(error)
        throw error
    }
}

export const addImageInCollection = async (payload) => {
    try {
        let response = await PutWithToken({baseUrl:'http://localhost:4200',endpoint:`/creator/collection/add-images`,data: { ...payload }})
        return response.data
    } catch (error) {
        console.error(error)
        throw error

    }
}



export const getCollectionById = async (collectionId) => {
    try {
        let response = await GetWithToken({endpoint:`/creator/collection/get-collection-by-id/${collectionId}`})
        return response.data
    } catch (error) {
        console.error(error)
        throw error
    }
}


export const publishCollection = async (data) => {
    try {
        let response = await PatchWithToken({baseUrl:'http://localhost:4200',endpoint:`/creator/collection/publish`,data})
        return response.data
    } catch (error) {
        console.error(error)
        throw error

    }
}



export const getAllCollections = async ({creator_id, searchText = '',page=1, limit=10}) => {
    try {
        let response = await GetWithToken({baseUrl:'http://localhost:4200',endpoint:`/creator/collection/get-collections-by-creatorid/${creator_id}?search=${searchText}&limit=${limit}&page=${page}`})
        return response.data
    } catch (error) {
        console.error(error)
        throw error

    }
}

export const getUserProfile = async ({ creator_id, isReviewed = 1, isPublished = 1, isShow = 1, isViewAllInStorePreview = 0 }) => {
    try {
        let response = await GetWithToken({endpoint:`/creator/profile/get-profile-by-id?creator_id=${creator_id}&isReviewed=${isReviewed}&isPublished=${isPublished}&isShow=${isShow}&isViewAllInStorePreview=${isViewAllInStorePreview}`})
        return response.data
    } catch (error) {
        console.error(error)
        throw error

    }
}

export const editUserProfile = async (payload) => {
    try {
        let response = await PutWithToken({endpoint:`/creator/profile/update-profile-by-id?creator_id`,data: payload})
        return response.data
    } catch (error) {
        console.error(error)
        throw error

    }
}



export const getAllCreators = async ({ limit, offset, search }) => {
    try {
        const { data } = await GetWithToken({baseUrl:'http://localhost:4200',endpoint:`/creator/get-all-creators?limit=${limit}&offset=${offset}&search=${search}`})
        return data;
    } catch (error) {
        throw error;
    }
}



export const getAllProjectsByCreatorId = async ({ creator_id, searchText = '', offset, limit, projectStatus = 'in_progress' }) => {
    try {
        let response = await GetWithToken({ baseUrl: 'http://localhost:4200', endpoint: `/creator/projects/get-projects-by-creatorId/${creator_id}?search=${searchText}&limit=${limit}&offset=${offset}&project_status=${projectStatus}` })
        return response.data
    } catch (error) {
        console.error(error)
        throw error

    }
}






export const deleteCollection = async (collectionId) => {

    try {
        let response = await DeleteWithToken({endpoint:`/creator/collection/delete/${collectionId}`})
        return response.data
    } catch (error) {
        console.error(error)
        throw error

    }
}



export const deleteCollectionImages = async (galleryContentIds) => {
    try {
        let response = await DeleteWithToken({endpoint:`/creator/collection/delete-gallery-images`,data:{
            galleryContentIds
        }});
        return response.data;
    } catch (error) {
        console.error(error)
        throw error

    }
}


export const hideUnhideCollectionImages = async (payload) => {
    try {
        let response = await PatchWithToken({endpoint:`/creator/collection/hide-gallery-images`,data:payload})
        return response.data
    } catch (error) {
        console.error(error)
        throw error
    }
}


export const deleteGalleryImageByCreatorId = async (payload) => {
    try {
        let response = await PatchWithToken({endpoint:`/creator/delete-gallery-image-by-creator-id`,data:payload})
        return response.data
    } catch (error) {
        console.error(error)
        throw error
    }
}




