// Modal.js
import React, { useEffect, useRef } from 'react';

const Modal = ({ open, onClose, children, containerClass='' }) => {
  const modalRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (open) {
      // Attach the event listener
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      // Clean up the event listener when modal is closed
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      // Clean up the event listener when component unmounts
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open, onClose]);

  if (!open) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-slate-500 bg-opacity-50 backdrop-blur-sm z-[998]">
      <div
        ref={modalRef}
        className={`bg-white rounded-lg shadow-lg p-6 relative w-fit ${containerClass}`}
      >
        {children}
      </div>
    </div>
  );
};

export default Modal;
