import React, { useEffect, useState } from 'react';
import PlayIcon from '../video/PlayIcon';
import CustomImage from './CustomImage';
import DownloadIcon from '../../icons/Download';
import LoadingWrapper from '../loadingWrapper/LoadingWrapper';
import { getFileMimeType, getIcon } from '../../pages/school/upload-project-content/helper';
import useModal from '../popups/modal/useModal';
import Modal from '../popups/modal/Modal';
import VideoPlayer from '../video/player/VideoPlayer';
import CrossIcon from '../../icons/CrossIcon';

const BehindScenePopupPreview = ({ setOpen, open, data }) => {
    const [isDownloading, setIsDownloading] = useState(false)
    const handleDownload = async (downloadUrl) => {
        try {
            setIsDownloading(true);
            const response = await fetch(downloadUrl);
            const blob = await response.blob();
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = data?.key || 'download'; // default name if not provided
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(link.href); // Clean up
            setIsDownloading(false);
        } catch (error) {
            console.error("Failed to download the file:", error);
            setIsDownloading(false);
        }
    };
    return (
        <Modal onClose={() => setOpen(false)} open={open} containerClass='p-3'>
            <div className="w-full flex items-center justify-end">
                <button className='absolute right-6 top-6 z-[99]' onClick={() => setOpen(false)}>
                    <CrossIcon size={'36px'} fill='rgb(51 65 85)' className="bg-slate-700" />
                </button>
            </div>
            <div className="w-[400px] h-[50vh]">
                <VideoPlayer
                    key={data?.videoUrl || ''}
                    src={data?.thumbnailUrl || ''}
                    alt={data?.videoName || "Image"}
                    imageClass="card-image"
                    cacheKey={data?.thumbnailUrl || ''}
                    //   onImageClick={() => handleImageClick(media)}
                    // shouldPlay={false}
                    videoUrl={data?.videoUrl}
                    imageContainerClass='bg-white'
                    playIconClass='right-12 top-4'
                />

                <button className='absolute right-8 bottom-8 hover:scale-105 hover:animate-bounce duration-100' onClick={() => handleDownload(data?.videoUrl)} >
                    <LoadingWrapper loading={isDownloading}>
                        <DownloadIcon stroke={'rgb(105 65 198)'} width='24' height='24' />
                    </LoadingWrapper>
                </button>
            </div>
        </Modal>

    )
}


function BehindSceneMedia({ currentMediaPreview, mediaData = [], imageClass, setCurrentMediaPreview, mainMediaData }) {
    console.log('mainMediaData', mainMediaData)
    console.log('mediaData', mediaData)
    const { open, setOpen, toggle } = useModal()
    const [popupData, setPopupData] = useState({})
    return (
        <div className="flex flex-row flex-wrap gap-1">
            {
                !!mediaData?.length &&
                <>
                <BehindSceneMediaItem isBehindScene={false} currentMediaPreview={currentMediaPreview} isMainMedia={true} setCurrentMediaPreview={setCurrentMediaPreview} mediaItem={mainMediaData} imageClass={imageClass} imageContainerClass='rounded-xl' />
                {<div className=" border-s-2 border-slate-300 h-20 mx-1"></div>
            }
                </>
            }
            {
                mediaData.map((mediaItem, i) => (
                    <BehindSceneMediaItem currentMediaPreview={currentMediaPreview} setCurrentMediaPreview={setCurrentMediaPreview} mediaItem={mediaItem} imageClass={imageClass} imageContainerClass='rounded-xl' />
                ))
            }
            {/* <BehindScenePopupPreview key='BehindScenePopupPreview' setOpen={setOpen} open={open} data={popupData}  /> */}
        </div>
    )
}

export default BehindSceneMedia

const BehindSceneMediaItem = ({isBehindScene=true, currentMediaPreview, isMainMedia = false, mediaItem, download = true, toggle, setPopupData, width, imageContainerClass = '', imageClass = '', setCurrentMediaPreview }) => {
    const [isDownloading, setIsDownloading] = useState(false);
    const [mediaType, setMediaType] = useState('');

    // Fetch media type (image/video) based on URL
    const getMediaType = async (url) => {
        if (url) {
            const type = await getFileMimeType(url);
            setMediaType(type);
        }
    };

    useEffect(() => {
        getMediaType(mediaItem?.videoUrl || mediaItem?.thumbnailUrl);
    }, [mediaItem]);

    // Handle download functionality
    const handleDownload = async (downloadUrl) => {
        try {
            setIsDownloading(true);
            const response = await fetch(downloadUrl);
            const blob = await response.blob();
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = mediaItem?.key || 'download'; // default name if not provided
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(link.href); // Clean up
            setIsDownloading(false);
        } catch (error) {
            console.error("Failed to download the file:", error);
            setIsDownloading(false);
        }
    };

    const handleMediaClick = () => {
        setCurrentMediaPreview({
            thumbnailUrl: mediaItem.thumbnailUrl,
            url: mediaItem.videoUrl,
            s3_key: mediaItem.videoUrl,
            mediaType: isMainMedia ? mediaItem.mediaType : 'video',
            isBehindScene:isBehindScene
        })
    }

    return (
        <div className="relative group size-20 rounded-xl">
            {/* Download Icon Overlay - Visible on Hover */}
            {download && <div
                className="absolute rounded-xl inset-0 z-[51] flex justify-center items-center hidden group-hover:flex bg-black bg-opacity-50"
                // onClick={() => handleDownload(mediaItem?.videoUrl)}
                onClick={handleMediaClick}
            >
                {/* <LoadingWrapper loading={isDownloading}>
                    <DownloadIcon stroke={'#fff'} className="text-white cursor-pointer w-10 h-10" />
                </LoadingWrapper> */}
            </div>
            }
            {/* Play Icon if thumbnail is available */}
            {mediaType?.includes('video') && <PlayIcon key={mediaItem.thumbnailUrl} center={true} />}

            {/* Image or Video Rendering */}
            <div className={`${currentMediaPreview.url === mediaItem.videoUrl && 'border-[#D6BBFB] border-[3px]'} bg-slate-300 rounded-xl flex justify-center  ${width ? `w-${width}` : "w-full h-full"}`}>

                {mediaType && (mediaType.includes('image') || mediaType.includes('video')) ?
                    <CustomImage
                        src={mediaItem.thumbnailUrl || mediaItem.videoUrl}
                        alt={mediaItem.key}
                        imageClass={imageClass || "card-image"}
                        imageContainerClass={imageContainerClass || ` h-full`}
                    // imageContainerClass="thump-image"
                    // imageClass="w-full h-full object-cover"
                    />

                    :
                    <div className={`${imageContainerClass || "h-full"}  flex justify-center h-[53px] icons-files`}>
                        {getIcon(mediaItem?.file_name, mediaType)}
                    </div>

                }

            </div>
        </div>
    );
};
