import React, { useState, useEffect, useRef } from "react";
import classes from "./video-player.module.css";
import moment from "moment";
import { useDispatch } from "react-redux";
import { setGalleryScrollPostion } from "../../../redux/actions";
import PlayIcon from "../PlayIcon";
import { formatSeconds } from "../../../utils/formatSeconds";

const VideoPlayer = ({
  shouldPlay=true,
  src = "",
  alt = "",
  imageClass = "",
  skeltonClass = "",
  cacheKey,
  onImageClick = () => null,
  imageContainerClass = "",
  videoUrl = "", // Add videoUrl prop
  children,
  playIconClass='top-4 right-4',
  showThumbnail=true
}) => {
  console.log(showThumbnail,"showThumbnail")
  const [loadingState, setLoadingState] = useState(true);
  const [imageError, setImageError] = useState(false);
  const [imageUrl, setImageUrl] = useState(cacheKey ? "" : src);
  const [showVideo, setShowVideo] = useState(false); // State to manage video display
  const elemRef = useRef();
  const dispatch = useDispatch();
  const placeholderSrc =
    "https://rezista.in/wp-content/uploads/2020/07/Image-Placeholder-Dark.png";

  const handleOnload = () => {
    setLoadingState(false);
  };

  const handleError = () => {
    setImageError(true);
    setLoadingState(true);
  };

  const handleLoadStart = () => {
    setLoadingState(true);
  };

  useEffect(() => {
    setImageError(false);
  }, [src]);

  useEffect(() => {
    if (cacheKey) {
      const cachedUrl = JSON.parse(sessionStorage.getItem(cacheKey));
      if (
        cachedUrl &&
        cachedUrl.expiry &&
        moment(cachedUrl.expiry).isAfter(moment())
      ) {
        setImageUrl(cachedUrl.url);
      } else {
        setImageUrl(src);
        sessionStorage.setItem(
          cacheKey,
          JSON.stringify({ url: src, expiry: moment().add({ hour: 1 }) })
        );
      }
    } else {
      setImageUrl(src);
    }
  }, [src, cacheKey]);

  const handlePlayVideo = () => {
    console.log("playyyy")
    setShowVideo(true); // Show the video player
  };

  const handleContextMenu = (event) => {
    event.preventDefault();
  };


  const [duration, setDuration] = useState()
  const videoRef = useRef()
  useEffect(() => {
    const handleLoadedMetadata = () => {
      if (videoRef.current) {

        setDuration(videoRef.current.duration); // Get duration when metadata is loaded
       
      }
    };
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.addEventListener('loadedmetadata', handleLoadedMetadata);
    }

    // Cleanup event listener on unmount
    return () => {
      if (videoElement) {
        videoElement.removeEventListener('loadedmetadata', handleLoadedMetadata);
      }
    };
  }, [videoUrl]);


  return (
    <div ref={elemRef} onContextMenu={handleContextMenu} className={`group relative h-full w-full bg-black max-w-[650px]  ${imageContainerClass}`}>

        {
          showThumbnail ?
          <>
            <div className="relative w-full h-full" style={{display:shouldPlay && showVideo ? 'block':'none'}}>
              <video ref={videoRef} controls  controlsList="nodownload" className="w-full h-full max-h-[650px]">
                <source src={videoUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <span className="relatives" style={{display:showVideo?'none':'inline'}} >
              <PlayIcon playIconClass={playIconClass} duration={duration ? formatSeconds(duration) : null} />
              <img
                onLoad={handleOnload}
                onLoadStart={handleLoadStart}
                onError={handleError}
                onClick={()=>{
                  onImageClick()
                  handlePlayVideo()
                }}
                src={imageError ? placeholderSrc : imageUrl}
                alt={alt}
                className={`${imageClass} object-cover`}
                style={
                  loadingState
                    ? {
                      opacity: 0,
                      position: "absolute",
                    }
                    : {}
                }
                loading="lazy"
              />
            </span>
          </>
          :
          // <div className="relative w-full h-full" style={{display:shouldPlay && showVideo ? 'block':'none'}}>
            <video ref={videoRef} controls  controlsList="nodownload" className="w-full h-full max-h-[650px]">
              <source src={videoUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          // </div>
        }



      {/* {showVideo ? (
        <div className="relative w-full h-full">
          <video ref={videoRef} controls autoPlay className="w-full h-full max-h-[650px]">
            <source src={videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      ) : (
        <span className="relatives">
          <PlayIcon onClick={handlePlayClick} />
          <img
            onLoad={handleOnload}
            onLoadStart={handleLoadStart}
            onError={handleError}
            src={imageError ? placeholderSrc : imageUrl}
            alt={alt}
            className={`${imageClass}`}
            style={
              loadingState
                ? {
                  opacity: 0,
                  position: "absolute",
                }
                : {}
            }
            loading="lazy"
          />
        </span>
      )} */}
      {React.isValidElement(children)
        ? React.cloneElement(children, { isVideo: showVideo, handleClick: handlePlayVideo })
        : children}
      {/* {children ? children : null} */}
      <div
        style={{
          display: !loadingState && "none",
        }}
        className={`${classes.skeleton} ${skeltonClass} `}
      ></div>
    </div>
  );
};

export default VideoPlayer;
