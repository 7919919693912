import React, { useEffect, useState } from 'react'
import EditIcon from '../../../icons/Edit'
import { useNavigate } from 'react-router-dom'
import TargetLocationIcon from '../../../icons/TargetLocation'
import LeftArrowIcon from '../../../icons/Buttons/LeftArrowIcon'
import RightArrowIcon from '../../../icons/Buttons/RightArrowIcon'
import { getCreatorServer, getUserProfile } from '../../../services/creatorService'
import { useDispatch, useSelector } from 'react-redux'
import { SocialLinks } from 'social-links';
import { getSocialIcons } from '../../../utils/getIcons';
import CustomImage from '../../../components/CustomImage/CustomImage';
import WebIcon from '../../../icons/WebIcon'
import { profile, profileData } from '../../../redux/actions'
import { extractUsernameFromXCom, isXCom } from '../../../utils/urlUtils'
import { formatName } from '../../../utils/formatName'

import GrayCameraIcon from '../../../icons/GrayCameraIcon'
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import ProgressBar from '../../../components/ProgressBar'
import SemiCircularProgressBar from '../../../components/ProgressBar'
import LoadingWrapper from '../../../components/loadingWrapper/LoadingWrapper'
import VideoPlayer from '../../../components/video/player/VideoPlayer'



const ViewCreatorProfile = () => {
    const socialLinks = new SocialLinks();
    const dispatch = useDispatch();

    const navigate = useNavigate()
    const { userId } = useSelector(state => state.user);

    const [loading, setLoading] = useState(true)
    const [userData, setUserData] = useState(null)

    const EditButton = ({ text, className }) => {
        return (
            <button onClick={() => navigate('/creator/profile/edit')} className={` border-[1px] gap-2 rounded-lg p-2 px-3  border-text-seconday-gray  flex ${className} `}> <EditIcon size={20} /> {text || "Edit"}</button>
        )
    }

    const Badge = ({ text }) => {
        return text && <span className='p-1 rounded-[50px] border-[#D9D6FE] bg-[#F4F3FF] text-violet border-1 border-full px-2 py-1 '>{text || ""}</span>
    }

    useEffect(() => {
        (async function () {
            setLoading(true)
            try {
                let response = await getUserProfile({ creator_id: userId, isPublished: 1, isReviewed: 1, isShow: 1, isViewAllInStorePreview: 1 });
                setUserData(response.data)
                dispatch(profile(response?.data?.profile_pic_url || ""));
                dispatch(profileData(response?.data || {}));

            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false)
            }
        })();
    }, [userId])

    const [profileCompletePercentage, setProfileCompletePercentage] = useState(0)
    const calculateProfileCompletePercentage = ({ profile_pic_key, bio, name, alias, title, location, social_links, skills }) => {


        const totalFields = 8;
        let completedFields = 0;

        if (profile_pic_key) completedFields += 1;
        if (bio) completedFields += 1;
        if (name) completedFields += 1;
        if (alias) completedFields += 1;
        if (title) completedFields += 1;
        if (location) completedFields += 1;
        if (social_links && social_links.length > 0) completedFields += 1;
        if (skills && skills.length > 0) completedFields += 1;

        const percentage = (completedFields / totalFields) * 100;
        setProfileCompletePercentage(percentage);
    }


    useEffect(() => {
        calculateProfileCompletePercentage({ ...userData })
    }, [userData])

    const getSocialLink = (link) => {
        if (!link) return
        if (isXCom(link)) {
            let icon = getSocialIcons('twitter')
            const profileId = extractUsernameFromXCom(link)
            return (
                <a className='no-underline flex gap-3 py-2 text-black items-center' rel="noreferrer" target='_blank' href={link}>
                    <div className='max-w-[60px]'>{icon}</div>
                    {profileId}
                </a>
            )
        }
        const profileName = socialLinks.detectProfile(link)
        if (!profileName) {

            let icon = getSocialIcons(profileName)
            return (
                <a className='truncate no-underline flex gap-3 py-2 text-black items-center' rel="noreferrer" target='_blank' href={link}>
                    <div className='max-w-[60px]'>{icon}</div>
                    {
                        link.length > 20 ? `${link.substring(0, 20)}...` : link
                    }
                </a>
            )

        }

        const profileId = socialLinks.getProfileId(profileName, link)
        let icon = getSocialIcons(profileName)
        return (
            <a className='no-underline flex gap-3 py-2 text-black items-center' rel="noreferrer" target='_blank' href={link}>
                <div className='max-w-[60px]'>{icon}</div>
                {profileId}
            </a>
        )
    }

    const handleGetCreator = async () => {
        await getCreatorServer()
    }

    const shortText = (text) => {
        return ((text.length > 7) ? text.slice(0,7) + "..." : text);
      }

    return (

        <div className="content-area">
            <div className="row m-0 ">
                <div className="col-lg-12 px-0 ">
                    <div className="h-[185px] relative">
                        <img
                            src="/creator/banner_img.png"
                            alt="Home Page Banner"
                            className="w-full max-h-full min-h-full object-cover"
                        />

                        <div className={`rounded-full absolute z-10 overflow-hidden top-[128px] w-[174px] h-[174px] left-[50px] ${userData?.profile_pic_url && "border-1 bg-gray-400"}`}>

                            {userData?.profile_pic_url ? <img src={loading ? "/home/circle2.png" : userData?.profile_pic_url} className="w-full h-full object-cover" alt="__" /> : <GrayCameraIcon size={174} />}
                        </div>
                    </div>
                </div>
                <LoadingWrapper loading={loading}>
                    <div className="col-lg-12 mt-3 mb-5 custom-max-width">

                        <div className="row align-items-center">
                            <div className="col-lg-12 ">

                                <div className="flex justify-start gap-[24px]">

                                    <div className='pl-[247px]'>
                                        <div className='text-[30px] font-[600] text-primary-black'>{userData?.alias || formatName(userData?.first_name, userData?.last_name)}</div>
                                        <span className='text-seconday'>{userData?.title || "Creator at swiirl"}</span>
                                    </div>
                                    <div className=''>
                                        <EditButton text='Edit profile' className={'border-violet text-violet'} />
                                    </div>
                                </div>
                                {/* {!isLoading && !hasCommission && <NoCommission />} */}
                            </div>
                        </div>
                    </div>
                    <div className='custom-max-width col-lg-11 mt-3'>
                        <div className='px-[55px]' >
                            <div className="flex justify-start items-center w-full p-4 border border-gray-200 rounded-lg">
                                <div className="flex items-center">
                                    <div className="mr-5">
                                        <SemiCircularProgressBar percentage={profileCompletePercentage || 10} />
                                    </div>
                                    <div className=''>
                                        <p className="text-lg font-semibold m-0">Your profile is {profileCompletePercentage || 10}% complete</p>
                                        <p className="text-sm text-gray-500 m-0">
                                            Stand out to buyers! Complete your profile with photo, social links, skills, and more.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-11 mt-3 custom-max-width  pt-[4rem] ">
                        <div className=' px-[55px]'>
                            <div className='w-full min-h-[400px] border-t-[1px] border-b-[1px]  border-text-seconday-gray flex'>
                                <div className='col-3 border-r-[1px]  border-text-seconday-gray '>
                                    <div className='flex flex-col gap-3 mt-5 '>
                                        <h4 className='font-[600] text-primary-black'>Skills</h4>
                                        <div className='min-h-[100px]'>
                                            <div className='flex mb-4 gap-3 flex-wrap'>
                                                {
                                                    userData?.skills?.split(",").map((item) => {
                                                        const shortenText = shortText(item);
                                                        return (
                                                            <div key={item}>
                                                                <Badge text={shortenText} />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            {!userData?.skills && <EditButton text='Update skills' />}
                                        </div>
                                        <div className='flex flex-col gap-3 mt-5  '>
                                            <h4 className='font-[600] text-primary-black'>Connect</h4>
                                            <div className='min-h-[200px]'>
                                                <div className=' mb-4'>
                                                    <div className='flex  items-center gap-3'>
                                                        <div className='w-[25px]'>
                                                            <TargetLocationIcon size={25} />
                                                        </div>
                                                        <div>{userData?.location} </div>
                                                    </div>
                                                    <div className='mt-3'>
                                                        {/* { userData?.social_links} */}
                                                        {(userData?.social_links || "").split(',').map((link, index) => <div className='' key={link + index}>{getSocialLink(link)}</div>)}
                                                    </div>
                                                </div>
                                                {!userData?.social_links && <EditButton text='Update social links' />}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='w-full'>
                                    <div className='mt-5d p-5'>
                                        <div className='flex mb-4 justify-between items-center'>

                                            <h4 className='font-[600] text-primary-black'>Work</h4>
                                            <button className='border-[1px] border-violet p-2 rounded text-violet' onClick={() => navigate("/creator/profile/work", { state: { images: userData?.images, userName: userData?.alias || userData?.name || userData?.first_name || "-" } })}>View all in store preview</button>
                                        </div>
                                        <div className='min-w-[550px]'>
                                            <CustomCarousel carouselItems={userData?.images} />

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='min-h-[150px] border-b-[1px] flex  border-text-seconday-gray '>
                                <div className='flex flex-col gap-3 mt-5 col-3 '>
                                    <h4 className='font-[600] text-primary-black'>About {userData?.alias || userData?.name || userData?.first_name || ""}</h4>

                                </div>
                                <div className='p-5 '>
                                    {!userData?.bio ? <EditButton text='Update bio' /> :
                                        <p className='py-2' >{userData?.bio}</p>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </LoadingWrapper>
            </div>
        </div>

    )
}

export default ViewCreatorProfile




const CustomCarousel = ({ carouselItems = [] }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselItems.length);
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? carouselItems.length - 1 : prevIndex - 1
        );
    };

    if (!carouselItems.length) return null

    return (
        <div className='w-full h-[200px]s'>
            <div className=' flex items-center justify-center'>

                <div className='text-center cursor-pointer col-1' onClick={handlePrev}>
                    {currentIndex > 0 && <LeftArrowIcon stroke={'rgba(0, 0, 0, 0.24)'} size={60} />}
                </div>

                {/* <div className='border-[1px] min-w-[450px] min-h-[400px] p-4 col-10 rounded-lg flex justify-center items-center overflow-hidden'> */}
                <div className=' border-[1px] col-10 rounded-lg'>
                    <div className=' text-center  flex justify-center  h-[480px] p-4  '>

                        {/* <div className='flex justify-center  bg-black w-full h-full'> */}
                        {/* </div> */}
                        {carouselItems[currentIndex]?.media_type === 'video' ?
                            <div className='flex justify-center '>
                                <VideoPlayer videoUrl={carouselItems[currentIndex].url} src={carouselItems[currentIndex].thumbnailUrl} cacheKey={carouselItems[currentIndex].thumbnailUrl} alt="image details page " imageContainerClass='max-w-[99%]' imageClass='h-full' />
                            </div>
                            :
                            <CustomImage
                                key={carouselItems[currentIndex]?.url}
                                src={carouselItems[currentIndex].url}
                                alt={carouselItems[currentIndex].alt || "Image"}
                                imageClass="card-image"
                                imageContainerClass="girdImage"
                            >

                            </CustomImage>
                        }
                        {/* <CustomImage imageClass={'h-full  '} imageContainerClass={"flex justify-center"} src={carouselItems[currentIndex].url} alt={`Slide ${currentIndex + 1}`} cacheKey={carouselItems[currentIndex].s3_key} /> */}

                        {/* <img src={carouselItems[currentIndex].url} alt={`Slide ${currentIndex + 1}`} className='w-full h-full object-cover rounded-lg' /> */}
                    </div>
                </div>

                <div className='text-center cursor-pointer col-1' onClick={handleNext}>
                    {currentIndex < carouselItems.length - 1 && <RightArrowIcon stroke={'rgba(0, 0, 0, 0.24)'} size={60} />}
                </div>
            </div>


        </div>
    );
};
