import React, { useContext, useState } from 'react';
import BorderLessButton from './buttons/BorderLessButton';
import DeleteIcon from '../icons/Delete';
import EyeOff from '../icons/EyeOff';
import CustomImage from './CustomImage/CustomImage';
import CreatorGalleryCardActions from './CreatorGalleryCardActions/CreatorGalleryCardActions';
import { deleteCollectionImages, hideUnhideCollectionImages } from '../services/creatorService';
import DeletePopup from './popups/DeletePopup';
import useToast from '../hooks/useToast';
import VideoPlayer from '../components/video/player/VideoPlayer'
import { SidebarContext } from '../App';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ContentGallery = ({ columns, contentData, refresh, setRefresh, disabledClick, isSubmited }) => {
    const showToast = useToast()
    const navigate = useNavigate()
    const {userType} = useSelector(state=>state.user)
    const { setGlobalLoader } = useContext(SidebarContext)

    const [selectedImages, setSelectedImages] = useState([]);
    const [openDeleteModel, setOpenDeleteModel] = useState(false)

    const [deleteLoading, setDeleteLoading] = useState(false); //
    const [hideLoading, setHideLoading] = useState(false)



    const getGalleryActionButtonDetails = () => {
        return selectedImages?.length ? { color: '#7F56D9' } : { color: '#98A2B3' };
    };

    const handleSelectAllChange = (event) => {
        if (event.target.checked) {
            const images = contentData?.images || [];
            const ids = images.filter((item) => !item?.isLicenced).map((item) => item?.gallery_content_id);
            setSelectedImages(ids);
        } else {
            setSelectedImages([]);
        }
    };


    const handleDeleteImages = async () => {
        try {
            setDeleteLoading(true)
            const resp = await deleteCollectionImages(selectedImages);
            setRefresh(!refresh)
            if (resp?.status === 200) {
                setSelectedImages([]); // Clear selected images after successful deletion
            }
            showToast("Images Deleted successfully", 'success')

        } catch (error) {
            console.log(error)
        } finally {
            setDeleteLoading(false)

        }

    };

    const handleHideFromStore = async (type = "hide") => {
        try {
            setHideLoading(true)
            const payload = {
                gallery_content_ids: selectedImages,
                type
            };
            await hideUnhideCollectionImages(payload);
            showToast(`Images ${type} from store  successfully`, 'success')


            setSelectedImages([]); // Clear selected images after successful hide/unhide

            setRefresh(!refresh)
        } catch (error) {
            console.log(error)
        } finally {
            setHideLoading(false)
        }


    };


    const handleDeleteModel = (state = false, type = 'collection') => {
        setOpenDeleteModel(state)
    }

    const anyImageHidden = selectedImages.some(id => {
        const image = contentData?.images?.find(img => img.gallery_content_id === id);
        return !image?.isShow;
    });

    const handleClickToEdit = (contentData) => { 
        navigate(`/${userType}/project/upload?type=edit`, { state: { contentData: { ...contentData, type: 'edit',isSubmited } } })
    }



    return (
        <div>
            <DeletePopup
                isOpen={openDeleteModel}
                title='Delete image?'
                subText='Your images will be permanently removed from our database and/or store.'
                onClose={() => handleDeleteModel(false)}
                onDelete={handleDeleteImages}
                loading={deleteLoading}

            // isDelete={isLicenced ? false : true}        // this use when collection is not deletable
            // type={'collection'}    // this use when collection is not deletable

            />
            {/* <div className="flex justify-between items-center mt-[43px] w-full">
                <div>
                    <div className={`${selectedImages.length ? 'flex' : 'hidden'} gap-[4px] items-center`}>
                        <input onChange={handleSelectAllChange} type="checkbox" className="rounded-sm w-[15px] h-[15px]" />
                        <label className="text-sm text-violet font-normal" htmlFor="">Select all</label>
                    </div>
                </div>
                <div className="flex items-center gap-[2px]">
                    <BorderLessButton
                        onClick={() => setOpenDeleteModel(true)}
                        disabled={deleteLoading || hideLoading || selectedImages.length === 0}
                        style={{ color: getGalleryActionButtonDetails().color }}
                        Icon={<DeleteIcon color={getGalleryActionButtonDetails().color} className="w-[15px]" />}
                        text="Delete"
                    />
                   
                </div>
            </div> */}
            <div className="parent mt-5">
                {columns && columns.length > 0 ? columns.map((column, colIndex) => (
                    <div className="column" key={colIndex}>
                        {column.map((obj, index) => (
                            <div
                                className={`image-container1 ${selectedImages.includes(obj.id) ? 'imageSelected' : ''}`}
                                style={{ minHeight: '400px' }}
                                key={index}
                                onClick={() => handleClickToEdit(obj)}

                            >
                                {
                                    obj?.media_type === 'video' ?
                                        <VideoPlayer
                                            videoUrl={obj.url}
                                            src={obj.thumbnailUrl}
                                            cacheKey={obj.thumbnailUrl}
                                            alt="image details page "
                                            imageContainerClass='max-w-[99%]' >
                                        </VideoPlayer>
                                        :
                                        <CustomImage
                                            key={index}
                                            src={obj.url}
                                            alt={obj.alt || "Image"}
                                            imageClass="card-image"
                                            imageContainerClass="girdImage"
                                        >
                                            {/* <CreatorGalleryCardActions
                                                data={{...obj,gallery_content_id:obj?.id}}
                                                setSelectedImages={setSelectedImages}
                                                selectedImages={selectedImages}
                                                showDownLable={false}
                                            /> */}
                                        </CustomImage>
                                }
                            </div>
                        ))}
                    </div>
                ))
                    :
                    <h2 className='text-center w-full mt-5'>No Images</h2>}
            </div>
        </div>
    );
};

export default ContentGallery;
