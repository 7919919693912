import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Galleries.css";
import CustomImage from "./CustomImage/CustomImage";
import { getGalleryContents } from "../services/brandServices";
import useDebounce from "../utils/debounce";
import ImageCardActions from "./ImageCardActions/ImageCardActions";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SearchGroup from "./searchGroup/SearchGroup";
import { makeGalleryArray } from "../utils/galleryUtils";
import { galleryDataFilterPurchased, galleryDataPreventFetching, galleryDataSuccess, setGalleryDataPage, setGalleryScrollPostion } from "../redux/actions";
import VideoThumbnail from "./video/thumbnail/VideoThumbnail";
import VideoPlayer from "./video/player/VideoPlayer";
import LoadingWrapper from './loadingWrapper/LoadingWrapper'
import { getLocalStorage, setLocalStorage } from "../lib/session";
import InfiniteScroll from "react-infinite-scroll-component";


function ImageGallery({ limit = 30, title = 'Browse content', shopButton = false, onLoadingChange, filterDropDownKey }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [searchText, setSearchText] = useState("");
  const [favFilterState, setFavFilterState] = useState(getLocalStorage(filterDropDownKey) || "all")
  const [imageTypeFilter, setImageTypeFilter] = useState("all");
  const [showViewMore, setShowViewMore] = useState(true)
  const [columns, setColumns] = useState([]);
  const [dataLength, setDataLength] = useState(0)

  // console.log('===============')
  // columns.map(item=>item.map(elm=>console.log(elm.id)))
  // console.log('===============')
  
  const { username, userId, brandId } = useSelector((state) => state.user);
  const { imageData, preventFetchingData, scrollPosition, page } = useSelector((state) => state.galleryData);
  
  // console.log(showViewMore,"===showviewmore===",favFilterState,"favFilterState",{preventFetchingData, scrollPosition, page})
  const debounceSearchText = useDebounce(searchText, 500);
  limit = parseInt(limit);
  // limit = 2;
  const navigate = useNavigate()
  const offset = page * limit - limit;
  const dispatch = useDispatch();

  useEffect(()=>{
    // console.log('==useeffect==')
    setLocalStorage(filterDropDownKey,favFilterState)
    setGalleryDataPreventFetching(false)
    setShowViewMore(true)
  },[favFilterState])

  const setPage = (newPage) => {
    dispatch(setGalleryDataPage(newPage))
  }

  const fetchGallery = async (loadMore) => {
    if (isLoadingMore || isLoading) return
    if (preventFetchingData) {
      setIsLoading(false)
      setIsLoadingMore(false)
      return;
    }
    loadMore && setIsLoadingMore(true)
    !loadMore &&  setIsLoading(true)
    try {
      let data = await getGalleryContents({ limit, offset: loadMore ? offset : 0, search: debounceSearchText, brand_id: brandId, favoriteFilter: favFilterState, imageTypeFilter: imageTypeFilter });
      let imgData = data.data || []
      // imgData = [
      //   ...videos,
      //   ...imgData.map(item=>({
      //     ...item,
      //     mediaType:"PhotoGraph"
      //   }))
      // ]
      
      // if (loadMore) setImageData([...imageData, ...imgData]);
      // else setImageData(imgData);
      dispatch(galleryDataSuccess(imgData))
      if (imgData.length < limit) {
        setShowViewMore(false)
      } else {
        setShowViewMore(true)
      }
    } catch (error) {
      console.log("error", error)
    } finally {
      setIsLoading(false)
      setIsLoadingMore(false)
    }
  }
  useEffect(() => {
    fetchGallery()
    if (!preventFetchingData) {
      setPage(1)
    }
  }, [debounceSearchText, favFilterState, imageTypeFilter]);

  useEffect(() => {
    if (imageData.length) {
      setIsLoading(true)
      setColumns(makeGalleryArray(imageData))
      setDataLength(prev=>prev+imageData.length)
      setIsLoading(false)
    }
  }, [imageData])
  // const columns = makeGalleryArray(imageData)

  useEffect(() => {
    if (scrollPosition && scrollPosition > 0 && imageData?.length && columns.length) {
      let pos = scrollPosition;
      if (shopButton) {
        pos = scrollPosition + 520;
      }
      window.scrollTo({ top: pos, behavior: "instant" });
      dispatch(setGalleryScrollPostion(0))
    }
  }, [columns])

  const refetchAfterPurchase = (image) => {
    // fetchGallery()
    dispatch(galleryDataFilterPurchased(image))
  }

  useEffect(() => {
    if (page !== 1) fetchGallery(true)
  }, [limit, offset])

  // const columns = [[], [], []]; // Three columns

  // // Distribute images equally among the columns
  // imageData.forEach((image, index) => {
  //   columns[index % 3].push(image);
  // });

  const setGalleryDataPreventFetching = (bool) => {
    dispatch(galleryDataPreventFetching(bool))
  }

  const handleImageClick = (image) => {
    setGalleryDataPreventFetching(true)
    navigate("/image-details", { state: { image } });
  };

  const handleFilterChange = (e) => {
    setLocalStorage(filterDropDownKey,e.target.value)
    setGalleryDataPreventFetching(false)
    setFavFilterState(e.target.value)
  }

  const handleImageTypeChange = (e) => {
    setGalleryDataPreventFetching(false)
    setImageTypeFilter(e.target.value)
  }

  const handleSearchText = (text)=>{
    setGalleryDataPreventFetching(false)
    setSearchText(text)
  }

  const shortenText = (text, maxLength) => {
    if (text.length > maxLength) {
        return text.substring(0, maxLength) + "...";
    }
    return text;
}

  const handleViweMore = () => {
    if (shopButton) navigate('/shop')
    else {
      setGalleryDataPreventFetching(false)
      const newPage = page + 1
      setPage(newPage)
    }
  }

  useEffect(() => {
    onLoadingChange && onLoadingChange(isLoading || isLoadingMore);
   }, [isLoading, isLoadingMore]);

  return (
    <div
      className="default-image-container p-5 custom-max-width"
      style={{ position: "relative" }}
    >
      <p className="text-[36px] font-[600] text-center">{title}</p>
      <SearchGroup
        favFilterState={favFilterState}
        handleFilterChange={handleFilterChange}
        handleImageTypeChange={handleImageTypeChange}
        imageTypeFilter={imageTypeFilter}
        searchText={searchText}
        setSearchText={handleSearchText}
        className="mb-3"
        searchLoading={isLoadingMore}
      />
      <>
        {/* {isLoading ? (
          <div className="loader-container">
            <img
              src="/Loader/ImageLoader.svg"
              className="loader-middle"
              alt="Loading"
            />
          </div>
        ) : ( */}
          <div>

            <LoadingWrapper loading={isLoading}>
                <InfiniteScroll
                  dataLength={dataLength}
                  next={handleViweMore}
                  hasMore={shopButton ? false : showViewMore}
                >
              <div className="parent">
                  {
                    imageData?.length ? columns.map((column, colIndex) => (
                      <div className="column" key={colIndex}>
                        {column.map((media, index) => (
                          <div className="image-container1" style={{ minHeight: '400px' }} key={index}>
                            {
                              media.media_type === 'video' ?
                                <VideoPlayer
                                  key={media?.s3_key || ''}
                                  src={media?.thumbnailUrl || ''}
                                  alt={media?.description || "Image"}
                                  imageClass="card-image"
                                  cacheKey={media?.thumbnailUrl || ''}
                                  onImageClick={() => handleImageClick(media)}
                                  shouldPlay={false}
                                  videoUrl={media?.url}
                                >
                                  <ImageCardActions cardData={media} onPurchaseSuccess={() => refetchAfterPurchase(media)} />
                                </VideoPlayer>
                                :
                                <CustomImage
                                  key={media.s3_key}
                                  src={media.thumbnailUrl	 || media.url}
                                  alt={media.description || "Image"}
                                  imageClass="card-image"
                                  cacheKey={media.s3_key}
                                  onImageClick={() => handleImageClick(media)}
                                >

                                  <ImageCardActions cardData={media} onPurchaseSuccess={() => refetchAfterPurchase(media)} />
                                </CustomImage>
                            }
                          </div>
                        )
                        )}
                      </div>
                    )) : <div className="py-24 flex justify-center w-full text-center"><p>No image related to {shortenText(searchText,50)} found</p> </div>
                  }
              </div>
                </InfiniteScroll>
            </LoadingWrapper>

            <LoadingWrapper loading={isLoadingMore} />

          {!isLoading && shopButton && (
            <div className="flex justify-center mt-4">
              <button onClick={handleViweMore} disabled={isLoadingMore} className="custom-border-violet rounded-lg px-3 py-2 text-violet">
                View more
              </button>
            </div>
          )}
          </div>
        {/* )} */}
      </>
    </div>
  );
}

export default ImageGallery;
