import React, { useCallback, useState } from 'react';
import CloudUploadIcon from '../icons/CloudUpload';
import AddRounded from '../icons/AddRounded';
import { formatFileSize, getIcon } from '../pages/school/upload-project-content/helper';
import DeleteIcon from '../icons/Delete';

const MediaUpload = React.memo(({ prevEditData, setPrevEditData, contentSubmitted = false, media, setMedia, id, formType, label, isImportant, helperText, accept = "*", disableUpload = false, disabledText = "" }) => {
    const [dragging, setDragging] = useState(false);

    const handleDragOver = useCallback((event) => {
        event.preventDefault(); // Prevent default behavior to allow dropping
        setDragging(true);
    },[]);

    const handleDragLeave = useCallback(() => {
        setDragging(false);
    },[]);

    const handleDrop = useCallback((event) => {
        event.preventDefault(); // Prevent default behavior
        setDragging(false);

        const files = Array.from(event.dataTransfer.files);

        // Map over the selected files and add them to the media state
        const updatedMedia = files.map(file => ({
            file,
            thumbnail: null, // Initially no thumbnail
        }));

        setMedia((prevFiles) => [...prevFiles, ...updatedMedia]);
    },[]);

    // Handle file selection
    const handleFileChange = useCallback((e) => {
        const files = Array.from(e.target.files);

        // Map over the selected files and add them to the media state
        const updatedMedia = files.map(file => ({
            file,
            thumbnail: null, // Initially no thumbnail
            isNew: true
        }));

        setMedia(prevMedia => [...prevMedia, ...updatedMedia]);
    },[])

    // Function to handle the thumbnail upload for a specific media item
    const handleThumbnailUpload = useCallback((index, thumbnailFile) => {
        const updatedMedia = [...media];
        updatedMedia[index].thumbnail = thumbnailFile;
        setMedia(updatedMedia);
    },[media])

    // Remove file or thumbnail
    const removeFile = useCallback((index) => {
        if (setPrevEditData) {
            setPrevEditData(prev => prev.map(item => {
                if (media[index].file.key === item.videoKey) {
                    item.removed = true
                }
                return item
            }))
        }
        const updatedMedia = [...media];
        // Remove the file at the given index
        updatedMedia.splice(index, 1);
        setMedia(updatedMedia);
    },[media])

    return (
        <div className="w-full flex flex-col gap-4">
            <div className="upload-section">
                <div>
                <label className={`${isImportant ? 'imp' : ''}`}>
                    {label}
                </label>
                </div>
                {
                    // (formType !== "edit" || (formType === "edit" && media.length !== 1)) &&
                    !disableUpload ?
                        <label
                            className={`upload-control1  border-violet border-2 ${dragging ? "border-dashed rounded-[12px]" : ""
                                }`}
                            htmlFor={id}
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                            onDrop={handleDrop}
                        >
                            <input
                                type="file"
                                multiple
                                id={id}
                                accept={accept}
                                onChange={handleFileChange}
                            />
                            <div className="upload-icon p-2 mb-3 rounded-md">
                                <CloudUploadIcon />
                            </div>
                            <div className="upload-options">
                                <span className="click">Click to upload</span>
                            </div>
                            <div className="mt-0 p-0 text-gray-500">
                                {helperText}
                            </div>
                        </label>
                        :
                        <DisabledInput helperText={helperText} />
                    // <p className="text-lg mt-2 text-slate-600 font-semibold">{disabledText}</p>
                }
            </div>

            <div className="media-list">
                {
                    <ul
                        style={{ listStyleType: "none", margin: 0, padding: 0 }}
                        className="flex flex-col gap-y-3"
                    >
                        {media && !!media.length && media.map((mediaItem, index) => (
                            <li className="selected-files my-1 flex flex-col gap-1" key={mediaItem.file.key || mediaItem.file.url || mediaItem.file.name}>
                                <div className="selected-file-grouping">
                                    <div className="icons-files">{getIcon(mediaItem?.file?.name || "")}</div>
                                    <div className="file-details">
                                        <div className="filename">{mediaItem?.file?.name}</div>
                                        <div className="filetype">
                                            ({formatFileSize(mediaItem?.file?.size || "")})
                                        </div>
                                    </div>
                                    {
                                        !contentSubmitted &&
                                        <div
                                            className="cursor-pointer"
                                            onClick={() => removeFile(index)}
                                        >
                                            <DeleteIcon />
                                        </div>
                                    }
                                </div>
                                {
                                    renderPreview({ url: mediaItem?.isNew ? URL.createObjectURL(mediaItem.file) : mediaItem?.file?.url, fileName: mediaItem?.file?.name, mediaType: mediaItem?.file.type })
                                }
                                {
                                    mediaItem?.file?.type?.includes('video') &&
                                    <ThumbnailUpload
                                        mediaIndex={index}
                                        id={id}
                                        onThumbnailUpload={handleThumbnailUpload}
                                        isNew={mediaItem?.isNew}
                                        existingThumbnail={mediaItem.thumbnail}
                                        contentSubmitted={contentSubmitted}
                                    />
                                }
                            </li>
                        ))}
                    </ul>
                }

            </div>
        </div>
    );
})

export default MediaUpload;

const ThumbnailUpload = React.memo(({ id, mediaIndex, onThumbnailUpload, existingThumbnail, isNew = true, contentSubmitted }) => {
    const [thumbnail, setThumbnail] = useState(existingThumbnail);

    // Handle thumbnail file change
    function handleThumbnailChange(e) {
        const file = e.target.files[0];
        if (file) {
            setThumbnail(file);
            onThumbnailUpload(mediaIndex, file);
        }
    }

    return (
        <div className='w-full flex flex-col'>
            <label
                className="ml-8 flex flex-row items-center gap-2 w-fit cursor-pointer"
                htmlFor={`upload-thumbnail-${thumbnail?.name}_${mediaIndex}_${id}`}
            >
                <input
                    type="file"
                    id={`upload-thumbnail-${thumbnail?.name}_${mediaIndex}_${id}`}
                    accept="image/*"
                    className='hidden'
                    onChange={handleThumbnailChange}
                    disabled={contentSubmitted}
                />
                {thumbnail ? (
                    <>
                        <p>Thumbnail: </p>
                        <p className='text-sm text-slate-800'>{thumbnail?.name}</p>
                    </>
                ) : (
                    <>
                        <p><AddRounded className="w-[15px]" /></p>
                        <p className='text-sm text-slate-800'>Add video thumbnail (png, jpg or jpeg)</p>
                    </>
                )}
            </label>
            {
                thumbnail &&
                renderPreview({ url: isNew ? URL.createObjectURL(thumbnail) : thumbnail?.url, fileName: thumbnail?.name, mediaType: thumbnail?.type })
            }
        </div>
    );
})
const placeholderSrc ="https://rezista.in/wp-content/uploads/2020/07/Image-Placeholder-Dark.png";
export function renderPreview({ mediaType = "", url, fileName = "" }) {
    if (mediaType.includes('image')) {
        return url && <img key={url||placeholderSrc} src={url||placeholderSrc} alt={fileName} className=' sd w-96 h-96 mx-auto object-contain flex self-center my-auto' />
    }
    if (mediaType.includes('video')) {
        return <video key={url} controls src={url} alt={fileName} className='w-96 h-96 mx-auto object-contain flex self-center my-auto' />
    }

    return null
}



function DisabledInput({ helperText }) {
    return (
        <label
            className={`upload-disable-control flex  border-slate-300 border-2`}
        >
            <div className="upload-icon p-2 mb-3 rounded-md">
                <CloudUploadIcon />
            </div>
            <div className="upload-options">
                <span className="click">Click to upload</span>
            </div>
            <div className="mt-0 p-0 text-gray-500">
                {helperText}
            </div>
        </label>
    )
}

