import React, { useEffect, useState } from 'react'
import { StyledTableBodyCell, StyledTableHeadCell } from '../../components/StyledTableCell/StyledTableCell'
import { getAllBrands } from '../../services/brandServices'
import Pagination from '../../components/Pagenation/Pagenation'
import moment from 'moment'
import LoadingWrapper from '../../components/loadingWrapper/LoadingWrapper'

const BrandList = () => {

    const [brandData, setBrandData] = useState([])
    const [loading, setLoading] = useState(false)
    const [limit, setLimit] = useState(10)

    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)

    const handlePageChange = (pageNo) => {
        setCurrentPage(pageNo)
    }



    const handleGetBrandData = async (currentPage) => {
        setLoading(true)
        try {
            const payload = {
                limit,
                offset: limit * (currentPage - 1)
            }
            const resp = await getAllBrands(payload)
            setTotalPages(Math.ceil(resp.totalCount / limit))
            setBrandData(resp?.brandDetails || [])
            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    useEffect(() => {
        handleGetBrandData(currentPage)
    }, [currentPage])

    const makeTableData = (field) => {
        if (field) return field
        else return '-'
    }

    return (
        <div>
            <p className="text-[36px] font-semibold text-center py-3">Brand List</p>

            <LoadingWrapper loading={loading}>

                <div className="w-full overflow-auto">
                    <table>
                        <thead>
                            <tr className="font-style-grid">
                                {/* <StyledTableHeadCell><span className="font-bold">Sr. No</span></StyledTableHeadCell> */}
                                <StyledTableHeadCell><span className="font-bold">Id</span></StyledTableHeadCell>
                                <StyledTableHeadCell><span className="font-bold">Email</span></StyledTableHeadCell>
                                <StyledTableHeadCell><span className="font-bold">User Type</span></StyledTableHeadCell>
                                <StyledTableHeadCell><span className="font-bold">Name</span></StyledTableHeadCell>
                                <StyledTableHeadCell><span className="font-bold">Email Verified</span></StyledTableHeadCell>
                                <StyledTableHeadCell><span className="font-bold">Created At</span></StyledTableHeadCell>
                                <StyledTableHeadCell><span className="font-bold">Updated At</span></StyledTableHeadCell>



                            </tr>
                        </thead>
                        <tbody>
                            {brandData.map((brand, index) => (
                                <tr
                                    key={brand.id}
                                    className={
                                        index % 2 === 0
                                            ? "even-row font-style-grid"
                                            : "odd-row font-style-grid"
                                    }
                                >
                                    <StyledTableBodyCell>{makeTableData(brand.cognito_id)}</StyledTableBodyCell>
                                    <StyledTableBodyCell>{makeTableData(brand?.email)}</StyledTableBodyCell>
                                    <StyledTableBodyCell>{makeTableData(brand?.role)}</StyledTableBodyCell>
                                    <StyledTableBodyCell>{makeTableData(brand.brand_name || brand?.organization)}</StyledTableBodyCell>
                                    <StyledTableBodyCell>{makeTableData(brand?.email_verified ? 'Verified' : 'Not Verified')}</StyledTableBodyCell>
                                    <StyledTableBodyCell>{makeTableData(moment(brand.created_at).format('MMMM DD, YYYY'))}</StyledTableBodyCell>
                                    <StyledTableBodyCell>{makeTableData(moment(brand.updated_at).format('MMMM DD, YYYY'))}</StyledTableBodyCell>

                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </LoadingWrapper>


            <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={(pageNo) => handlePageChange(pageNo)} />
        </div>
    )
}

export default BrandList